import { useEffect, useRef, useState } from 'react';
import {
  HiChevronDown as ChevronDownIcon,
  HiEllipsisHorizontal as EllipsisHorizontalIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { Button, Collapsible } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageDropdownMenu } from '@/pages/pages/page-tree/page-dropdown-menu/PageDropdownMenu';
import { type PageUrlParams } from '@/Router';

interface ItemWrapperProps {
  page: BuilderPage;
  children: React.ReactNode;
  classes: string;
  to: string;
  isActive?: boolean;
  isCollapsibleOpen?: boolean;
  style: React.CSSProperties;
}

export function ItemWrapper({
  page,
  children,
  classes,
  to,
  isActive,
  isCollapsibleOpen,
  style
}: ItemWrapperProps) {
  const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);
  const { isSearching } = useLeftPanelContext();
  const urlParams = useParams<PageUrlParams>();
  const { id: selectedPageKey } = urlParams;
  const { attributes, listeners, transform, isDragging } = useSortable({
    id: page.key,
    disabled: isSearching
  });
  const itemRef = useRef<HTMLDivElement>(null);
  const isCollapsible = typeof isCollapsibleOpen !== 'undefined';
  const rightButtonWrapperClasses = 'absolute flex size-8 items-center justify-center gap-2';
  const rightButtonClasses = cn('invisible', {
    'group-hover:visible': !transform,
    visible: isDragging
  });
  const rightButtonHoverClasses = cn('size-7', {
    'visible focus:bg-brand-100': isActive || isPageDropdownOpen,
    'hover:bg-emphasis': !isActive,
    'hover:bg-brand-200': isActive
  });
  const { data: app } = useApplicationQuery();
  const hasSingleLoginPage = app?.users.scope === 'application';

  useEffect(() => {
    if (isActive) {
      itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isActive]);

  const handleDropdownMenuTrigger = (isOpen: boolean, pageKey: string) => {
    if (isOpen) {
      setIsPageDropdownOpen(pageKey === page.key);
    } else {
      setIsPageDropdownOpen(false);
    }
  };

  return (
    <div className="group relative overflow-hidden" ref={itemRef}>
      {!isSearching &&
        ((hasSingleLoginPage && page.parentSlug) ||
          (!hasSingleLoginPage && !page.menuPageKey && !page.parentSlug)) && (
          <div
            data-testid="tables-rearrange-icon"
            className={cn(
              'invisible absolute left-1 top-2.5 cursor-grab pr-1 align-middle active:cursor-grabbing',
              {
                'group-hover:visible': !transform,
                visible: isDragging
              }
            )}
            {...listeners}
            {...attributes}
          >
            <DragIcon className="size-4" />
          </div>
        )}

      <Link
        to={to}
        style={style}
        data-testid="page-tree-item-link"
        className={cn(
          {
            'bg-brand-100 text-emphasis': isActive,
            'group-hover:bg-subtle': !isActive && !transform,
            'bg-subtle':
              (transform && isDragging && selectedPageKey !== page.key) ||
              (isPageDropdownOpen && !isActive)
          },
          classes
        )}
      >
        <div className="flex max-w-[calc(100%-60px)] items-center gap-1">{children}</div>
      </Link>
      <PageDropdownMenu
        page={page}
        onDropdownMenuOpenChange={handleDropdownMenuTrigger}
        triggerElement={
          <div
            className={cn('right-8 top-0.5', rightButtonWrapperClasses, {
              'right-1': !isCollapsible
            })}
          >
            <Button
              intent="minimalStandalone"
              size="xs"
              className={cn(rightButtonClasses, rightButtonHoverClasses)}
            >
              <EllipsisHorizontalIcon size={20} />
            </Button>
          </div>
        }
      />
      {isCollapsible && (
        <Collapsible.Trigger
          data-testid="page-tree-item-collapsible-trigger"
          className={cn('right-1 top-1', rightButtonWrapperClasses)}
          asChild
        >
          <Button intent="minimalStandalone" size="xs" className={rightButtonHoverClasses}>
            <ChevronDownIcon
              size={16}
              className={cn('transition-transform duration-300', {
                'rotate-180': isCollapsibleOpen
              })}
            />
          </Button>
        </Collapsible.Trigger>
      )}
    </div>
  );
}
