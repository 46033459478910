import { HelmetProvider } from 'react-helmet-async';
import { SvgGradient, ThemeProvider, Toaster, Tooltip } from '@knack/asterisk-react';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { QueryClientProvider, type QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import type Rollbar from 'rollbar';

import { ErrorFallback } from '@/components/errors/ErrorFallback';
import { LanguageSwitcher } from '@/components/layout/LanguageSwitcher';

if (import.meta.env.MODE !== 'testing') {
  // eslint-disable-next-line no-console
  console.log(
    `%cKnack Builder (${APP_VERSION})`,
    'background: #982a86; color: #ffffff; font-size: 20px; padding:5px'
  );
  // eslint-disable-next-line no-console
  console.log(`%cLast modification: ${APP_LAST_MOD}`, 'font-size: 14px;');
}

if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.PUBLIC_LOGROCKET_APP_ID, {
    rootHostname: import.meta.env.PUBLIC_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com',
    dom: {
      baseHref: `${window.location.protocol}//${window.location.hostname}/`
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body?.includes('"password":')) {
          const params = JSON.parse(request.body);
          params.password = '********';
          request.body = JSON.stringify(params);
        }

        // otherwise log the request normally
        return request;
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

const rollbarConfig: Rollbar.Configuration = {
  enabled: import.meta.env.MODE !== 'testing',
  accessToken: import.meta.env.PUBLIC_ROLLBAR_ACCESS_TOKEN,
  environment: 'production', // Use 'testenv' for testing
  code_version: APP_VERSION,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: APP_VERSION,

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

interface AppProvidersProps {
  children: React.ReactNode;
  queryClient: QueryClient;
}

export function AppProviders({ children, queryClient }: AppProvidersProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <FlagsmithProvider
        options={{
          environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENV_ID
        }}
        flagsmith={flagsmith}
      >
        <HelmetProvider>
          <RollbarProvider config={rollbarConfig}>
            <ErrorBoundary fallbackUI={ErrorFallback}>
              <ThemeProvider>
                {import.meta.env.DEV && <LanguageSwitcher />}
                <Toaster />
                <SvgGradient gradientId="gradient-1" />
                <SvgGradient gradientId="gradient-2" />
                <Tooltip.Provider>{children}</Tooltip.Provider>
              </ThemeProvider>
            </ErrorBoundary>
          </RollbarProvider>
        </HelmetProvider>

        <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
      </FlagsmithProvider>
    </QueryClientProvider>
  );
}
