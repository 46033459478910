import i18n from 'i18next';
import snakeCase from 'lodash.snakecase';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { getBooleanFieldLabel } from '@/utils/fields';
import { getRuleTimeFormatted } from '@/utils/rules/getRuleTimeFormatted';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { InlineKnackValue } from '@/components/InlineKnackValue';

function getDateTimeFilterValue(filter: KnackFilter) {
  const { operator, type, range, value } = filter;

  if (
    operator === 'is during the previous' ||
    operator === 'is during the last' ||
    operator === 'is during the next' ||
    operator === 'is before the previous' ||
    operator === 'is after the next'
  ) {
    if (!type || !range) {
      return '';
    }

    return i18n.t(`attributes.field_labels.date_time.number_${snakeCase(type)}`, {
      number: range
    });
  }

  if (operator === 'is during the current') {
    if (!type) {
      return '';
    }

    return i18n.t(`attributes.field_labels.date_time.${type}`);
  }

  // If the filter value is not an object or does not have a date property, return an empty string
  if (typeof value !== 'object' || !('date' in value)) {
    return '';
  }

  if (!value.time) {
    return value.date || '';
  }

  const timeString = getRuleTimeFormatted(value.hours, value.minutes);
  return `${value.date || ''} ${timeString}`.trim();
}

export function FilterValue({
  filter,
  filterField
}: {
  filter: KnackFilter;
  filterField: KnackField;
}) {
  if (shouldHideValueBasedOnOperator(filter.operator)) {
    return null;
  }

  // If the filter value is an array, it means the filter field is a connection field, so we need to display the record value
  if (filterField.type === 'connection' && Array.isArray(filter.value)) {
    return filter.value.map((value, valueIndex) => (
      <InlineKnackRecordValue
        // eslint-disable-next-line react/no-array-index-key
        key={`${value}-${valueIndex}`}
        tableKey={filterField.relationship.object}
        recordId={value}
      />
    ));
  }

  let valueToReturn = '';

  if (filterField.type === 'boolean' && typeof filter.value === 'boolean') {
    valueToReturn = getBooleanFieldLabel(filterField.format, filter.value);
  } else if (filterField.type === 'date_time') {
    valueToReturn = getDateTimeFilterValue(filter);
  } else if (typeof filter.value === 'string') {
    valueToReturn = filter.value;
  }

  if (!valueToReturn) {
    return null;
  }

  return <InlineKnackValue value={valueToReturn} className="bg-subtle" />;
}
