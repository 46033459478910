import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiPencil as PencilIcon,
  HiCog6Tooth as SettingsIcon,
  HiMiniEllipsisHorizontal as ThreeDotsIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon, MdOutlineHistory as HistoryIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, DropdownMenu, useToast } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type Task } from '@/types/schema/tasks/KnackTask';
import { useTaskMutation } from '@/hooks/api/mutations/useTaskMutation';
import { cn } from '@/utils/tailwind';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { TaskCard } from '@/pages/tables/toolkit-sidebar/tasks/TaskCard';
import { TaskDeleteModal } from '@/pages/tables/toolkit-sidebar/tasks/TaskDeleteModal';
import { TaskForm } from '@/pages/tables/toolkit-sidebar/tasks/TaskForm';
import { TaskHistoryModal } from '@/pages/tables/toolkit-sidebar/tasks/tasks-history/TaskHistoryModal';

type TaskCardWrapperProps = {
  task: Task;
  table: KnackObject;
  hasTableMoreThanOneTask: boolean;
};

export function TaskCardWrapper({ task, table, hasTableMoreThanOneTask }: TaskCardWrapperProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const [shouldRenderEditForm, setShouldRenderEditForm] = useState(false);
  const [shouldShowDiscardChangesModal, setShouldShowDiscardChangesModal] = useState(false);

  const { editMutation, deleteMutation } = useTaskMutation();
  const [shouldShowDeleteTaskModal, setShouldShowDeleteTaskModal] = useState(false);
  const [shouldShowTaskHistoryModal, setShouldShowTaskHistoryModal] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: task.key || ''
  });

  const sortableItemStyles = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const onTaskSave = (taskData: Task) => {
    editMutation.mutate(
      {
        tableKey: table.key,
        taskKey: task.key,
        taskData
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.task_save_success')
          });
          setShouldRenderEditForm(false);
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.errors.task_save_error')
          });
        }
      }
    );
  };

  const handleDeleteTask = () => {
    deleteMutation.mutate(
      {
        tableKey: table.key,
        taskKey: task.key
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.delete_task.delete_success')
          });
          setShouldShowDeleteTaskModal(false);
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.delete_task.delete_error')
          });
          setShouldShowDeleteTaskModal(false);
        }
      }
    );
  };

  const handleDiscardChanges = () => {
    setShouldShowDiscardChangesModal(false);
    setShouldRenderEditForm(false);
  };

  return (
    <div data-testid={`task-card-wrapper-${task.key}`} ref={setNodeRef} style={sortableItemStyles}>
      {!shouldRenderEditForm && (
        <div className="group relative">
          {hasTableMoreThanOneTask && (
            <div
              data-testid={`task-card-rearrange-icon-${task.key}`}
              className={cn(
                'invisible absolute -left-2.5 top-4 cursor-grab rounded-l align-middle active:cursor-grabbing group-hover:visible group-hover:bg-subtle',
                {
                  'group-hover:visible': !transform,
                  visible: isDragging
                }
              )}
              {...listeners}
              {...attributes}
            >
              <DragIcon size={20} />
            </div>
          )}
          <TaskCard task={task} table={table}>
            <span
              className="invisible absolute right-2 top-2 space-x-2 bg-subtle group-hover:visible"
              data-testid="action-buttons"
            >
              <Button
                intent="minimal"
                size="xs"
                className="hover:bg-muted"
                data-testid="edit-task-button"
                onClick={() => setShouldRenderEditForm(true)}
              >
                <PencilIcon size={14} />
              </Button>

              <DropdownMenu>
                <DropdownMenu.Trigger data-testid={`task-${task.key}-contextual-menu`}>
                  <ThreeDotsIcon />
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                  <div className="flex flex-col">
                    <DropdownMenu.Item
                      data-testid={`edit-task-${task.key}-contextual-menu-option`}
                      onClick={() => {
                        setShouldRenderEditForm(true);
                      }}
                    >
                      <SettingsIcon size={18} className="mr-2" />
                      {t('components.data_table.right_sidebar.tasks.edit_task')}
                    </DropdownMenu.Item>
                  </div>

                  <div className="flex flex-col">
                    <DropdownMenu.Item
                      data-testid={`view-task-history-${task.key}-contextual-menu-option`}
                      onClick={() => {
                        setShouldShowTaskHistoryModal(true);
                      }}
                    >
                      <HistoryIcon size={18} className="mr-2" />
                      {t('components.data_table.right_sidebar.tasks.task_history.menu_option')}
                    </DropdownMenu.Item>
                  </div>

                  <DropdownMenu.Separator />

                  <div className="flex flex-col">
                    <DropdownMenu.Item
                      data-testid={`delete-task-${task.key}-contextual-menu-option`}
                      className="w-full text-destructive"
                      onClick={() => {
                        setShouldShowDeleteTaskModal(true);
                      }}
                    >
                      <DeleteIcon size={18} className="mr-2" />
                      {t('components.data_table.right_sidebar.tasks.delete_task.title')}
                    </DropdownMenu.Item>
                  </div>
                </DropdownMenu.Content>
              </DropdownMenu>
            </span>
          </TaskCard>
        </div>
      )}

      {shouldRenderEditForm && (
        <div className="relative gap-4 rounded-lg bg-muted p-4">
          <h4 className="p-2 font-semibold">
            {t('components.data_table.right_sidebar.tasks.edit_task')}
          </h4>
          <TaskForm
            table={table}
            existingTask={task}
            onCancel={() => {
              setShouldShowDiscardChangesModal(true);
            }}
            onTaskSave={onTaskSave}
          />
          <Button
            intent="minimal"
            size="xs"
            className="absolute right-4 top-4"
            data-testid="delete-task-button"
            onClick={() => {
              setShouldShowDeleteTaskModal(true);
            }}
          >
            <DeleteIcon size={14} />
          </Button>
        </div>
      )}

      <DiscardChangesModal
        isOpen={shouldShowDiscardChangesModal}
        onOpenChange={setShouldShowDiscardChangesModal}
        onConfirm={handleDiscardChanges}
      />

      <TaskDeleteModal
        isOpen={shouldShowDeleteTaskModal}
        onOpenChange={setShouldShowDeleteTaskModal}
        handleDeleteTask={handleDeleteTask}
      />

      {shouldShowTaskHistoryModal && (
        <TaskHistoryModal onOpenChange={setShouldShowTaskHistoryModal} task={task} />
      )}
    </div>
  );
}
