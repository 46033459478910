import { useContext } from 'react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type BuilderViewKey } from '@/types/schema/BuilderView';
import { createMessagingContext, MessagingContextProvider } from '@/contexts/MessagingContext';
import {
  type PageEditorItemToSelect,
  type PageEditorSelectedItem,
  type PageEditorUpdate
} from './helpers/types';

export type MessageFromLiveApp =
  | {
      action: 'select';
      itemToSelect: PageEditorItemToSelect;
    }
  | {
      action: 'update';
      update: PageEditorUpdate;
    }
  | {
      action: 'start-add-view';
      columnId: string;
      sectionId: string;
      originViewKey?: BuilderViewKey;
      position?: 'above' | 'below';
    }
  | {
      // This action can be used to start editing a field within a view.
      // It can be within any type of view
      action: 'start-edit-view-input';
      viewKey: BuilderViewKey;
      viewInputId: string;
    }
  | {
      action: 'request-page-sync';
    };

export type MessageToLiveApp =
  | {
      action: 'update';
      updatedPage: BuilderPage;
      selectedItem?: PageEditorSelectedItem;
    }
  | {
      action: 'select';
      selectedItem: PageEditorSelectedItem;
    }
  | {
      action: 'page-sync';
      page: BuilderPage;
      initialSelectedItem?: PageEditorSelectedItem;
    }
  | {
      action: 'display-data';
      dataType: 'live' | 'sample';
    };

const PageEditorMessagingContext = createMessagingContext<MessageToLiveApp, MessageFromLiveApp>();

export function PageEditorMessagingContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <MessagingContextProvider context={PageEditorMessagingContext}>
      {children}
    </MessagingContextProvider>
  );
}

export const usePageEditorMessagingContext = () => {
  const context = useContext(PageEditorMessagingContext);
  if (!context) {
    throw new Error(
      'usePageEditorMessagingContext must be used within a PageEditorMessagingContextProvider'
    );
  }
  return context;
};
