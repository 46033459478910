import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { ApiWrapper } from '@/utils/api-wrapper';
import { getBuilderBaseUrl } from '@/utils/application';
import { ROUTES } from '@/Router';

type CreateEmptyObjectParams = {
  objectName?: string;
};

async function createEmptyObject({ objectName = '' }: CreateEmptyObjectParams) {
  const response = await ApiWrapper.createObject(objectName);
  return response;
}

export function useCreateEmptyObjectMutation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return useMutation({
    mutationFn: createEmptyObject,
    onSuccess: (response) => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });

      const shouldRedirectToNewBuilder =
        searchParams.has('origin') && searchParams.get('origin') === 'builder-next';

      const oldBuilderPath = `${getBuilderBaseUrl()}/schema/list/objects/${response.object.key}`;
      const newBuilderPath = `${generatePath(ROUTES.TABLES_ID, {
        id: response.object.key ?? null
      })}`;

      if (shouldRedirectToNewBuilder) {
        navigate(newBuilderPath);
      } else {
        window.location.replace(oldBuilderPath);
      }
    }
  });
}
