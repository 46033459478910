import { useState, type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiDocumentDuplicate as DuplicateIcon,
  HiLockClosed as LockIcon,
  HiCog6Tooth as SettingsIcon
} from 'react-icons/hi2';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Dialog, DropdownMenu } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { ROUTES } from '@/Router';
import { DialogContent } from './DialogContent';

export type DialogContentType = 'new' | 'delete' | 'duplicate' | 'menu_settings' | 'remove_login';

export interface PageDropdownMenuProps {
  page: BuilderPage;
  triggerElement: React.ReactNode;
  dropdownContentAlign?: ComponentProps<typeof DropdownMenu.Content>['align'];
  onDropdownMenuOpenChange?: (isOpen: boolean, pageKey: string) => void;
}

export function PageDropdownMenu({
  page,
  triggerElement,
  dropdownContentAlign,
  onDropdownMenuOpenChange
}: PageDropdownMenuProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams<{ id: string }>();
  const { id: pageKey } = urlParams;
  const { setSettingsPanelItem } = usePagesPageContext();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContentType, setDialogContentType] = useState<DialogContentType | null>(null);
  const { data: app } = useApplicationQuery();
  const hasExtendedSettings = app?.users.scope === 'scene' || page.parentSlug || page.menuPageKey;

  const handleOnSettingsClick = () => {
    if (page.type === 'menu') {
      setIsDialogOpen(true);
      setDialogContentType('menu_settings');
      return;
    }

    // If opening the settings panel for the same page, just update the settings panel item
    if (pageKey === page.key) {
      setSettingsPanelItem({ type: 'page', page });
      return;
    }

    // Otherwise, navigate to the appropriate page
    navigate(generatePath(ROUTES.PAGES_ID, { id: page.key }));
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={(isOpen) => {
          if (onDropdownMenuOpenChange) {
            onDropdownMenuOpenChange(isOpen, page.id);
          }
          setDropdownOpen(isOpen);
        }}
        data-testid={`dropdown-menu-${page.id}`}
        modal={false}
      >
        <DropdownMenu.Trigger data-testid={`dropdown-trigger-${page.id}`} asChild>
          {triggerElement}
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          className="text-sm"
          data-testid={`dropdown-content-${page.id}`}
          align={dropdownContentAlign}
        >
          <DropdownMenu.Item
            onClick={handleOnSettingsClick}
            data-testid={`dropdown-settings-item-${page.id}`}
          >
            <SettingsIcon size={16} className="mr-2" />
            {t(`pages.page_tree.${page.type}_settings`)}
          </DropdownMenu.Item>
          {page.type === 'authentication' && (
            <DropdownMenu.Item
              onSelect={() => {
                setIsDialogOpen(true);
                setDialogContentType('remove_login');
              }}
              data-testid={`dropdown-remove-login-item-${page.id}`}
            >
              <LockIcon className="mr-2" size={16} />
              {t('pages.page_tree.remove_login')}
            </DropdownMenu.Item>
          )}
          {page.type !== 'menu' && hasExtendedSettings && (
            <DropdownMenu.Item
              onSelect={() => {
                setIsDialogOpen(true);
                setDialogContentType('duplicate');
              }}
              data-testid={`dropdown-duplicate-item-${page.id}`}
            >
              <DuplicateIcon className="mr-2" size={16} />
              {t(`pages.page_tree.${page.type}_duplicate`)}
            </DropdownMenu.Item>
          )}
          {hasExtendedSettings && (
            <>
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                onSelect={() => {
                  setIsDialogOpen(true);
                  setDialogContentType('delete');
                }}
                className="text-destructive"
                data-testid={`dropdown-delete-item-${page.id}`}
              >
                <DeleteIcon className="mr-2" size={16} />
                {t(`pages.page_tree.${page.type}_delete`)}
              </DropdownMenu.Item>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu>

      <DialogContent
        page={page}
        contentType={dialogContentType}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </Dialog>
  );
}
