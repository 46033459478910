import { DateTime } from 'luxon';

export type LuxonDateFormat = 'none' | 'dd/MM/yyyy' | 'MM/dd/yyyy';

const DEFAULT_LUXON_TIME_FORMAT = 'hh:mma';

export function useDateTimeHelpers() {
  const checkIsValidDate = (value: string, format: LuxonDateFormat) =>
    DateTime.fromFormat(value, format).isValid ||
    DateTime.fromFormat(value, format.replace('yyyy', 'yy')).isValid;

  const getFormattedDatePickerDate = (date: string, format: LuxonDateFormat) =>
    !date || !checkIsValidDate(date, format)
      ? DateTime.now().toJSDate()
      : DateTime.fromFormat(date, format.replace('yyyy', 'yy')).toJSDate();

  const getRepeatDefaultOptions = () => ({
    frequency: 'daily',
    interval: '1',
    SU: false,
    MO: false,
    TU: false,
    WE: false,
    TH: false,
    FR: false,
    SA: false,
    repeatby: 'dom',
    endson: 'never',
    end_count: '',
    end_date: ''
  });

  // Returns the current date in the specified luxon format
  const getCurrentDate = (format: LuxonDateFormat) => DateTime.now().toFormat(format);

  // Returns the current time in the default luxon format
  const getCurrentTime = () => DateTime.now().toFormat(DEFAULT_LUXON_TIME_FORMAT);

  return { getFormattedDatePickerDate, getRepeatDefaultOptions, getCurrentDate, getCurrentTime };
}
