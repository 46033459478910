import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackObject } from '@/types/schema/KnackObject';

const invalidTableTypes = ['EcommercePaymentObject', 'EcommercePaymentMethodObject', 'UserObject'];

export function getTableValidDisplayFields(table: KnackObject) {
  const invalidFieldTypes = ['signature', 'file', 'image', 'rating'];
  return table.fields.filter((field) => !invalidFieldTypes.includes(field.type));
}

export function hasTableValidDisplayFields(table: KnackObject) {
  return getTableValidDisplayFields(table).length > 0 && !invalidTableTypes.includes(table.type);
}

// Both functions below will converge into one function in the future
// when we add the User and Payment tables in v4.
export function getValidTablesForImport(app?: BuilderApplication) {
  return app?.objects?.filter((obj) => obj?.type !== 'EcommercePaymentObject') ?? [];
}

export function getValidTables(app?: BuilderApplication) {
  return (
    // TODO: We need it until we complete the following tickets:
    // - FE-1758: https://knack.atlassian.net/browse/FE-1758
    // - FE-1564: https://knack.atlassian.net/browse/FE-1564
    app?.objects?.filter((obj) => !invalidTableTypes.includes(obj?.type))
  );
}
