import { useTranslation } from 'react-i18next';
import {
  HiMiniCheckCircle as CheckIcon,
  HiMiniXCircle as CrossIcon,
  HiExclamationCircle as ExclamationIcon
} from 'react-icons/hi2';
import { SimplePagination, Table } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';
import { DateTime } from 'luxon';

import { type TaskHistoryStatus } from '@/types/schema/tasks/KnackTask';
import { cn } from '@/utils/tailwind';
import { TASK_HISTORY_MAX_VISIBLE_PAGES_IN_PAGINATION } from '@/pages/tables/toolkit-sidebar/tasks/constants';
import { RowsPerPageSelect } from '@/pages/tables/toolkit-sidebar/tasks/tasks-history/RowsPerPageSelect';
import { useTaskHistoryContext } from '@/pages/tables/toolkit-sidebar/tasks/tasks-history/TaskHistoryContext';

export function TaskHistoryTable() {
  const [t] = useTranslation();

  const { currentHistoryData, totalHistoryPageNumber, currentHistoryPage, onPageChange } =
    useTaskHistoryContext();

  const parseTaskHistoryDate = (date: string) => {
    if (!date) return '-';

    return DateTime.fromISO(date).toFormat('MM/dd/yyyy hh:mm a');
  };

  const getTaskHistoryStatusIcon = (status: TaskHistoryStatus) => {
    switch (status) {
      case 'Finished (Run Manually)':
      case 'Finished':
        return <CheckIcon className="shrink-0" size={16} />;
      case 'Canceled':
        return <CrossIcon className="shrink-0" size={16} />;
      case 'Failed':
        return <ExclamationIcon className="shrink-0" size={16} />;
      default:
        return null;
    }
  };

  if (currentHistoryData.length === 0) {
    return (
      <div className="flex items-center justify-center rounded-lg border border-dashed p-2 text-center text-sm">
        {t('components.data_table.right_sidebar.tasks.task_history.no_history')}
      </div>
    );
  }

  const taskHistoryHeaderCellClasses =
    'h-8 border-separate overflow-hidden text-ellipsis whitespace-nowrap border border-x-0 bg-muted';
  const taskHistoryCellClasses = 'overflow-hidden text-ellipsis whitespace-nowrap border-x-0 py-0';

  return (
    <div className="flex max-h-[452px] flex-col gap-2 overflow-hidden rounded-lg border border-default">
      <div className="w-full overflow-auto">
        <Table className="border-separate border-spacing-0 bg-base">
          <Table.Header className="border-0 bg-base">
            <Table.Row className="[&>:first-child]:rounded-tl-lg [&>:last-child]:rounded-tr-lg">
              <Table.Head className={taskHistoryHeaderCellClasses}>
                {t('components.data_table.right_sidebar.tasks.task_history.header.status')}
              </Table.Head>
              <Table.Head className={taskHistoryHeaderCellClasses}>
                {t('components.data_table.right_sidebar.tasks.task_history.header.start_time')}
              </Table.Head>
              <Table.Head className={taskHistoryHeaderCellClasses}>
                {t('components.data_table.right_sidebar.tasks.task_history.header.end_time')}
              </Table.Head>
              <Table.Head className={taskHistoryHeaderCellClasses}>
                {t(
                  'components.data_table.right_sidebar.tasks.task_history.header.records_processed'
                )}
              </Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentHistoryData.map((taskHistoryRow) => (
              <Table.Row
                className="h-9 [&>:first-child]:rounded-bl-lg [&>:last-child]:rounded-br-lg"
                key={taskHistoryRow.id}
              >
                <Table.Cell className={taskHistoryCellClasses}>
                  <span
                    className={cn('flex items-center gap-1', {
                      'text-success-default':
                        taskHistoryRow.status === 'Finished (Run Manually)' ||
                        taskHistoryRow.status === 'Finished',
                      'text-destructive': taskHistoryRow.status === 'Canceled'
                    })}
                  >
                    {getTaskHistoryStatusIcon(taskHistoryRow.status)}
                    {t(
                      `components.data_table.right_sidebar.tasks.task_history.row_status.${snakeCase(taskHistoryRow.status.toLowerCase())}`
                    )}
                  </span>
                </Table.Cell>
                <Table.Cell className={taskHistoryCellClasses}>
                  {parseTaskHistoryDate(taskHistoryRow.started_at)}
                </Table.Cell>
                <Table.Cell className={taskHistoryCellClasses}>
                  {parseTaskHistoryDate(taskHistoryRow.ended_at)}
                </Table.Cell>
                <Table.Cell className={taskHistoryCellClasses}>
                  {taskHistoryRow.processed_count || '-'}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="flex w-full justify-between border bg-muted p-2">
        <RowsPerPageSelect />
        <div className="mr-2 flex justify-end">
          <SimplePagination
            totalPages={totalHistoryPageNumber}
            currentPage={currentHistoryPage}
            onPageChange={onPageChange}
            maxVisiblePages={TASK_HISTORY_MAX_VISIBLE_PAGES_IN_PAGINATION}
          />
        </div>
      </div>
    </div>
  );
}
