import { IMask, useIMask } from 'react-imask';
import { Input, Select } from '@knack/asterisk-react';

import { type DateTimeField } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';

interface TimeInputProps {
  value: string;
  className?: string;
  children?: React.ReactNode;
  format: DateTimeField['format']['time_format'];
  disabled?: boolean;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
  onValueChange: (value: string) => void;
}

interface TimeModeSelectProps {
  value: 'AM' | 'PM';
  className?: string;
  disabled?: boolean;
  onValueChange: (value: 'AM' | 'PM') => void;
}

function TimeInput({
  value,
  className,
  inputProps,
  format,
  children,
  disabled,
  onValueChange
}: TimeInputProps) {
  const is12HourFormat = format === 'HH:MM am';
  const {
    ref: inputRef,
    value: iMaskValue,
    setValue
  } = useIMask<HTMLInputElement>(
    {
      mask: 'HH:MM',
      lazy: false,

      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: is12HourFormat ? 12 : 23
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        }
      }
    },
    {
      defaultValue: value,
      onComplete: (val) => {
        onValueChange(val);
      }
    }
  );

  const handleOnBlurPlaceholderReplace = () => {
    if (iMaskValue.includes('_')) {
      setValue(iMaskValue.replace(/_/g, '0'));
    }
  };

  return (
    <Input.Container className="flex">
      <Input
        disabled={disabled}
        ref={inputRef}
        className={cn('w-20 rounded-lg p-1 text-center text-sm', className, {
          'rounded-r-none focus:-outline-offset-2': children
        })}
        onBlur={handleOnBlurPlaceholderReplace}
        {...inputProps}
      />
      {children}
    </Input.Container>
  );
}

function TimeModeSelect({ value, className, disabled, onValueChange }: TimeModeSelectProps) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <Select.Trigger
        disabled={disabled}
        shouldHideChevron
        className={cn(
          'min-w-10 justify-center rounded-lg rounded-l-none p-1 text-sm focus:-outline-offset-2 [&>*:last-child]:ml-0',
          className
        )}
      />
      <Select.Content className="min-w-20">
        <Select.Item value="AM">AM</Select.Item>
        <Select.Item value="PM">PM</Select.Item>
      </Select.Content>
    </Select>
  );
}

const CompoundTimeInput = Object.assign(TimeInput, {
  TimeModeSelect
});

TimeModeSelect.displayName = 'TimeInput.TimeModeSelect';

export { CompoundTimeInput as TimeInput };
