import { Controller, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon, HiTableCells as TableIcon } from 'react-icons/hi2';
import { Banner, Button, Checkbox, Form, Label } from '@knack/asterisk-react';
import isEmpty from 'lodash.isempty';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { CopyTableOptions } from './DuplicateTableForm';

interface ConfirmCopyStepProps {
  table: KnackObject;
  errors: FieldErrors;
  copyTableOption: CopyTableOptions;
  control: any;
  selectedTableKey: string | null;
  app: BuilderApplication;
  selectNoneFields: () => void;
  handleBack: () => void;
  selectAllFields: () => void;
}

export function ConfirmCopyStep({
  table,
  errors,
  copyTableOption,
  control,
  selectedTableKey,
  app,
  selectNoneFields,
  selectAllFields,
  handleBack
}: ConfirmCopyStepProps) {
  const [t] = useTranslation();

  return (
    <>
      {!isEmpty(errors) && (
        <Banner closeMode="text" intent="destructive" icon={ExclamationIcon} type="inline">
          <Banner.Message>{t('components.dialogs.tables.duplicate.please_correct')}</Banner.Message>
          <ul className="ml-2 list-disc">
            {Object.values(errors).map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{`${error?.message}`}</li>
            ))}
          </ul>
        </Banner>
      )}
      <div className="flex">
        <div className="flex flex-wrap items-center gap-1">
          <span>{t('components.dialogs.tables.duplicate.copying')}</span>
          <div className="flex items-center gap-1 rounded bg-subtle px-1 py-0.5">
            <TableIcon size={16} />
            <span className="max-w-xs truncate">{table.name}</span>
          </div>
          {copyTableOption === CopyTableOptions.NewTable ? (
            <span>{t('components.dialogs.tables.duplicate.fields_into_new_table')}</span>
          ) : (
            <>
              <span>{t('components.dialogs.tables.duplicate.fields_into')}</span>
              <div className="flex items-center gap-1 rounded bg-subtle px-1 py-0.5">
                <TableIcon size={16} />
                <span className="max-w-xs truncate">
                  {app?.objects.find((object) => object.key === selectedTableKey)?.name}
                </span>
              </div>
            </>
          )}
        </div>
        <Button intent="minimalStandalone" className="ml-auto underline" onClick={handleBack}>
          {t('components.dialogs.tables.duplicate.change')}
        </Button>
      </div>
      <div>
        <div className="flex items-center">
          <span> {t('components.dialogs.tables.duplicate.select_fields_to_copy')}</span>
          <div className="ml-auto flex gap-2">
            <Button intent="minimalStandalone" className="underline" onClick={selectNoneFields}>
              {t('components.dialogs.tables.duplicate.select_none')}
            </Button>
            <Button intent="minimalStandalone" className="underline" onClick={selectAllFields}>
              {t('components.dialogs.tables.duplicate.select_all')}
            </Button>
          </div>
        </div>
        <Form.Section className="flex flex-col gap-1">
          {table.fields.map((field: KnackField) => (
            <div className="flex items-center gap-2" key={field.key}>
              <Controller
                name="fieldsToCopy"
                control={control}
                render={({ field: formField }) => (
                  <Checkbox
                    checked={formField.value.includes(field.key)}
                    onCheckedChange={(checked) => {
                      const isChecked = checked === true;
                      const newValues = isChecked
                        ? [...formField.value, field.key]
                        : formField.value.filter((k) => k !== field.key);
                      formField.onChange(newValues);
                    }}
                  />
                )}
              />
              <Label>{field.name}</Label>
            </div>
          ))}
        </Form.Section>
      </div>
    </>
  );
}
