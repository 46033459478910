import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Form, Input, Label, Select } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateTimeField, type DateTimeFieldRangeType } from '@/types/schema/fields';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { getDefaultTimeToString } from '@/components/import/confirm-import/date-time/helpers';
import { TimeInput } from '@/components/import/confirm-import/date-time/TimeInput';
import {
  defaultDateFormatMap,
  type DateTimePayload,
  type TimeValues
} from '@/components/import/confirm-import/date-time/types';

const dateRangeSingularOptions: DateTimeFieldRangeType[] = ['week', 'month', 'quarter', 'year'];

const dateRangePluralOptions: DateTimeFieldRangeType[] = [
  'days',
  'weeks',
  'months',
  'years',
  'rolling weeks',
  'rolling months',
  'rolling years'
];

export function DateTimeCriteriaValue({
  index,
  targetField
}: {
  index: number;
  targetField: DateTimeField;
}) {
  const { getFormattedDatePickerDate } = useDateTimeHelpers();
  const defaultDateFormat = targetField.format.date_format || 'mm/dd/yyyy';

  const dateFormat = defaultDateFormatMap[defaultDateFormat];
  const timeFormat = targetField.format.time_format || 'HH:MM am';

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);
  const { watch, setValue, getValues } = useFormContext();
  const [t] = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const todayFormatted = DateTime.now().toFormat(dateFormat);
  const today = DateTime.now().toJSDate();

  const [anyDate, setAnyDate] = useState(getValues(`criteria.${index}.value.date`) === '');
  const [anyTime, setAnyTime] = useState(getValues(`criteria.${index}.value.hours`) === '');

  const handleSelectDate = (
    date: Date,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...fieldValue,
      date: DateTime.fromJSDate(date).toFormat('MM/dd/yyyy'),
      dateFormatted: getFormattedDate(date)
    });
  };
  const operator: string = watch(`criteria.${index}.operator`);

  const daysOfWeek = [
    { label: t('attributes.field_labels.date_time.weekdays.monday'), value: 1 },
    { label: t('attributes.field_labels.date_time.weekdays.tuesday'), value: 2 },
    { label: t('attributes.field_labels.date_time.weekdays.wednesday'), value: 3 },
    { label: t('attributes.field_labels.date_time.weekdays.thursday'), value: 4 },
    { label: t('attributes.field_labels.date_time.weekdays.friday'), value: 5 },
    { label: t('attributes.field_labels.date_time.weekdays.saturday'), value: 6 },
    { label: t('attributes.field_labels.date_time.weekdays.sunday'), value: 0 }
  ];

  useEffect(() => {
    if (
      operator === 'is' ||
      operator === 'is not' ||
      operator === 'is before' ||
      operator === 'is after'
    ) {
      if (!getValues(`criteria.${index}.value`)) {
        if (timeFormat === 'Ignore Time') {
          if (!anyDate) {
            const baseValue = { date: todayFormatted, dateFormatted: todayFormatted, anyDate };
            setValue(`criteria.${index}.value`, baseValue, {
              shouldValidate: true,
              shouldDirty: true
            });
          }
        } else if (!anyTime) {
          const baseValue = {
            am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM',
            hours: DateTime.now().hour,
            minutes: DateTime.now().minute,
            rawTime: getDefaultTimeToString(targetField.format),
            date: todayFormatted,
            dateFormatted: todayFormatted,
            anyTime
          };
          setValue(`criteria.${index}.value`, baseValue, {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      } else if (typeof getValues(`criteria.${index}.value`) !== 'object') {
        const baseValue = { date: todayFormatted, dateFormatted: todayFormatted };

        setValue(`criteria.${index}.value`, baseValue, {
          shouldValidate: true,
          shouldDirty: true
        });
      }
    } else if (
      operator === 'is during the last' ||
      operator === 'is during the next' ||
      operator === 'is after the next' ||
      operator === 'is before the previous' ||
      operator === 'is during the previous'
    ) {
      if (!getValues(`criteria.${index}.range`)) {
        setValue(`criteria.${index}.range`, 1, { shouldValidate: true, shouldDirty: true });
      }
      if (!getValues(`criteria.${index}.type`)) {
        setValue(`criteria.${index}.type`, 'days', { shouldValidate: true, shouldDirty: true });
      } else if (dateRangeSingularOptions.includes(getValues(`criteria.${index}.type`))) {
        setValue(`criteria.${index}.type`, 'days', { shouldValidate: true, shouldDirty: true });
      }
    } else if (operator === 'is a day of the week') {
      if (!getValues(`criteria.${index}.value`)) {
        setValue(`criteria.${index}.value`, 1, { shouldValidate: true, shouldDirty: true });
      } else if (typeof getValues(`criteria.${index}.value`) !== 'number') {
        setValue(`criteria.${index}.value`, 1, { shouldValidate: true, shouldDirty: true });
      }
    } else if (operator === 'is during the current') {
      if (!getValues(`criteria.${index}.type`)) {
        setValue(`criteria.${index}.type`, 'week', { shouldValidate: true, shouldDirty: true });
      } else if (dateRangePluralOptions.includes(getValues(`criteria.${index}.type`))) {
        setValue(`criteria.${index}.type`, 'week', { shouldValidate: true, shouldDirty: true });
      }
    } else if (operator === 'is between days of the week') {
      if (!getValues(`criteria.${index}.from_date`)) {
        setValue(`criteria.${index}.from_date`, 1, { shouldValidate: true, shouldDirty: true });
      }
      if (!getValues(`criteria.${index}.to_date`)) {
        setValue(`criteria.${index}.to_date`, 5, { shouldValidate: true, shouldDirty: true });
      }
    } else if (operator === 'is between dates') {
      if (!getValues(`criteria.${index}.from_date`)) {
        setValue(
          `criteria.${index}.from_date`,
          { date: todayFormatted, dateFormatted: todayFormatted },
          { shouldValidate: true, shouldDirty: true }
        );
      }
      if (!getValues(`criteria.${index}.to_date`)) {
        setValue(
          `criteria.${index}.to_date`,
          { date: todayFormatted, dateFormatted: todayFormatted },
          { shouldValidate: true, shouldDirty: true }
        );
      }
    }
  }, [
    anyDate,
    anyTime,
    getValues,
    index,
    operator,
    setValue,
    targetField.format,
    timeFormat,
    todayFormatted
  ]);

  const handleChangeTime = (
    time: TimeValues,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { am_pm: amPM, rawTime } = time;

    onChange({
      ...fieldValue,
      rawTime,
      am_pm: amPM
    });
  };

  const handleAnyTimeCheckboxChange = () => {
    if (!anyTime) {
      setValue(`criteria.${index}.value`, {
        ...getValues(`criteria.${index}.value`),
        anyTime: true
      });
    } else {
      setValue(`criteria.${index}.value`, {
        ...getValues(`criteria.${index}.value`),
        anyTime: false
      });
    }
    setAnyTime(!anyTime);
  };

  const handleAnyDateCheckboxChange = () => {
    if (!anyDate) {
      setValue(`criteria.${index}.value`, {
        ...getValues(`criteria.${index}.value`),
        anyDate: true
      });
    } else {
      setValue(`criteria.${index}.value`, {
        ...getValues(`criteria.${index}.value`),
        anyDate: false
      });
    }
    setAnyDate(!anyDate);
  };

  const rangeList = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  const getOptionsList =
    operator === 'is during the current' ? dateRangeSingularOptions : dateRangePluralOptions;

  if (operator === 'is between days of the week') {
    return (
      <div>
        <Form.Label htmlFor="from_date">
          {t('components.data_table.right_sidebar.conditional_rules.from')}
        </Form.Label>
        <Controller
          name={`criteria.${index}.from_date`}
          render={({ field }) => (
            <Select
              value={daysOfWeek.find((day) => day.value === field.value)?.label || ''}
              defaultValue={daysOfWeek.find((day) => day.value === field.value)?.label || ''}
              onValueChange={(selectedOption: string) => {
                const selectedDay = daysOfWeek.find((day) => day.label === selectedOption)?.value;
                field.onChange(selectedDay);
              }}
            >
              <Select.Trigger id="from_date" className="w-full truncate" {...field} />
              <Select.Content>
                {daysOfWeek.map((day) => (
                  <Select.Item key={day.value} value={day.label}>
                    {day.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
        <Form.Label htmlFor="to_date">
          {t('components.data_table.right_sidebar.conditional_rules.to')}
        </Form.Label>
        <Controller
          name={`criteria.${index}.to_date`}
          render={({ field }) => (
            <Select
              onValueChange={(selectedOption: string) => {
                const selectedDay = daysOfWeek.find((day) => day.label === selectedOption)?.value;
                field.onChange(selectedDay);
              }}
              value={daysOfWeek.find((day) => day.value === field.value)?.label || ''}
              defaultValue={daysOfWeek.find((day) => day.value === field.value)?.label || ''}
            >
              <Select.Trigger id="to_date" className="w-full truncate" {...field} />
              <Select.Content>
                {daysOfWeek.map((day) => (
                  <Select.Item key={day.value} value={day.label}>
                    {day.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
    );
  }

  if (operator === 'is between dates') {
    return (
      <div className="flex flex-col">
        <Form.Label htmlFor="from_date">
          {t('components.data_table.right_sidebar.conditional_rules.from')}
        </Form.Label>
        <Controller
          name={`criteria.${index}.from_date`}
          render={({ field }) => {
            const formattedDate = field.value
              ? getFormattedDatePickerDate(field.value.dateFormatted, dateFormat)
              : today;

            return (
              <DatePicker
                triggerRef={inputRef}
                selected={formattedDate}
                onSelect={(val) => handleSelectDate(val, field.value, field.onChange)}
                mode="single"
              >
                <Input
                  onChange={() => {}}
                  id="from_date"
                  className="w-full truncate"
                  value={field.value?.dateFormatted || ''}
                  defaultValue={field.value?.dateFormatted || ''}
                  placeholder={defaultDateFormat}
                />
              </DatePicker>
            );
          }}
        />
        <Form.Label htmlFor="to_date">
          {t('components.data_table.right_sidebar.conditional_rules.to')}
        </Form.Label>
        <Controller
          name={`criteria.${index}.to_date`}
          render={({ field }) => {
            const formattedDate = field.value
              ? getFormattedDatePickerDate(field.value.dateFormatted, dateFormat)
              : today;

            return (
              <DatePicker
                triggerRef={inputRef}
                selected={formattedDate}
                onSelect={(val) => handleSelectDate(val, field.value, field.onChange)}
                mode="single"
              >
                <Input
                  id="to_date"
                  className="w-full truncate"
                  value={field.value?.dateFormatted || ''}
                  placeholder={defaultDateFormat}
                />
              </DatePicker>
            );
          }}
        />
      </div>
    );
  }

  if (operator === 'is a day of the week') {
    return (
      <Controller
        name={`criteria.${index}.value`}
        render={({ field }) => (
          <Select
            onValueChange={(selectedOption: string) => {
              const selectedDay = daysOfWeek.find((day) => day.label === selectedOption)?.value;
              field.onChange(selectedDay);
            }}
            value={daysOfWeek.find((day) => day.value === field.value)?.label ?? ''}
            defaultValue={daysOfWeek.find((day) => day.value === field.value)?.label ?? ''}
          >
            <Select.Trigger id="criteria-value-select" className="w-full truncate" {...field} />
            <Select.Content>
              {daysOfWeek.map((day) => (
                <Select.Item key={day.value} value={day.label}>
                  {day.label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (
    operator === 'is during the current' ||
    operator === 'is during the previous' ||
    operator === 'is during the last' ||
    operator === 'is during the next' ||
    operator === 'is after the next' ||
    operator === 'is before the previous'
  ) {
    return (
      <div className="flex gap-2">
        {operator !== 'is during the current' && (
          <Controller
            name={`criteria.${index}.range`}
            render={({ field }) => (
              <Select
                onValueChange={(selectedOption: string) => {
                  field.onChange(selectedOption);
                }}
                value={field.value}
                defaultValue={field.value}
              >
                <Select.Trigger className="w-full truncate" />
                <Select.Content>
                  {rangeList.map((option: string) => (
                    <Select.Item key={option} value={option}>
                      {option}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        )}
        <Controller
          name={`criteria.${index}.type`}
          render={({ field }) => (
            <Select
              onValueChange={(selectedOption: string) => {
                field.onChange(selectedOption);
              }}
              value={field.value}
              defaultValue={field.value}
            >
              <Select.Trigger className="w-full truncate" />

              <Select.Content>
                {getOptionsList.map((option: string) => (
                  <Select.Item key={option} value={option}>
                    {option}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
    );
  }

  if (getValues(`criteria.${index}.value`)) {
    return (
      <div className="flex gap-2">
        <div className="flex flex-col">
          <Controller
            name={`criteria.${index}.value`}
            render={({ field }) => {
              const formattedDate = getFormattedDatePickerDate(
                field.value.dateFormatted,
                dateFormat
              );
              return (
                <DatePicker
                  triggerRef={inputRef}
                  selected={formattedDate}
                  onSelect={(val) => handleSelectDate(val, field.value, field.onChange)}
                  mode="single"
                >
                  <Input
                    onChange={() => {}}
                    disabled={anyDate}
                    id={`${field.name}-date-input`}
                    value={field.value.dateFormatted}
                    placeholder={defaultDateFormat}
                  />
                </DatePicker>
              );
            }}
          />
          {timeFormat !== 'Ignore Time' && (
            <div className="mb-2">
              <Checkbox
                checked={anyDate}
                onCheckedChange={handleAnyDateCheckboxChange}
                data-testid="any-date-checkbox"
              />
              <Label className="ml-2">
                {t('components.data_table.attributes.field_labels.date_time.any_date')}
              </Label>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          {timeFormat !== 'Ignore Time' && (
            <Controller
              name={`criteria.${index}.value`}
              render={({ field }) => (
                <TimeInput
                  format={timeFormat}
                  selectedTime={field.value}
                  onChangeTime={(time) => handleChangeTime(time, field.value, field.onChange)}
                  isDisabled={anyTime}
                />
              )}
            />
          )}
          {timeFormat !== 'Ignore Time' && (
            <div className="mb-2">
              <Checkbox
                checked={anyTime}
                onCheckedChange={handleAnyTimeCheckboxChange}
                data-testid="any-time-checkbox"
              />
              <Label className="ml-2">
                {' '}
                {t('components.data_table.attributes.field_labels.date_time.any_time')}
              </Label>
            </div>
          )}
        </div>
      </div>
    );
  }
}
