import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';

export const TASK_ACTION_TYPE_OPTIONS = ['record', 'connection', 'insert', 'email'] as const;
export const TASK_RUN_STATUS_OPTIONS = ['running', 'paused'] as const;
export const TASK_TYPE = 'actions';
export const TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS = ['daily', 'weekly', 'monthly'] as const;
export const TASK_VALUE_TYPE_OPTIONS = ['value', 'record', 'connection'] as const;

export type TaskValueType = (typeof TASK_VALUE_TYPE_OPTIONS)[number];
export type TaskActionType = (typeof TASK_ACTION_TYPE_OPTIONS)[number];
export type TaskRunStatus = (typeof TASK_RUN_STATUS_OPTIONS)[number];
export type TaskScheduleRepeatFrequency = (typeof TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS)[number];

export interface TaskValue {
  connection_field?: string;
  field: KnackFieldKey; // The field that will be updated
  input?: KnackFieldKey; // The field where the value is coming from (if type is record)
}

export interface TaskRecordTypeValue extends TaskValue {
  type: 'record';
  value: string;
}

export interface TaskValueTypeValue extends TaskValue {
  type: 'value';
  value: string | boolean | string[];
}

export interface TaskConnectionTypeValue extends TaskValue {
  type: 'connection';
  value: string;
}

export type TaskConnectionKey = `${KnackObject['key']}.${KnackFieldKey}`;
export type TaskKey = `task_${string}`;
export interface Task {
  name: string;
  action: {
    action: TaskActionType;
    email: any; // TODO: FE-3407
    criteria: KnackCriteria[];
    connection: TaskConnectionKey;
    values: (TaskRecordTypeValue | TaskValueTypeValue | TaskConnectionTypeValue)[];
  };
  run_status: TaskRunStatus;
  type: typeof TASK_TYPE;
  object_key: KnackObject['key'];
  schedule: {
    repeat: TaskScheduleRepeatFrequency;
    date?: string;
    time?: string;
  };
  key: TaskKey;
}

export type TaskHistoryStatus = 'Finished' | 'Canceled' | 'Finished (Run Manually)' | 'Failed';

export interface TaskHistoryData {
  id: string;
  object_key: KnackObject['key'];
  started_at: string;
  processed_count: number;
  total_count: number;
  status: TaskHistoryStatus;
  ended_at: string;
}
