import { Controller, FormProvider, useForm, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, Form, Input, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';
import { z } from 'zod';

import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { FieldIcon } from '@/components/FieldIcon';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from './ConnectionFieldInput';
import { MultipleChoiceFieldInput } from './MultipleChoiceFieldInput';

interface FilterFormProps {
  originObject: KnackObject;
  filter: KnackFilter;
  onCancel: () => void;
  onSave: (filter: KnackFilter) => void;
}

export const NOT_IMPLEMENTED_FIELD_TYPES_IN_FILTERS: KnackFieldType[] = ['date_time', 'timer'];

export function FilterForm({ originObject, filter, onCancel, onSave }: FilterFormProps) {
  const [t] = useTranslation();
  const { getBaseFieldOperators } = useFieldHelpers();

  const filterFormSchema = z
    .object({
      field: z.string(),
      operator: z.string(),
      value: z.union([z.string(), z.boolean(), z.array(z.string()), z.record(z.any())])
    })
    .superRefine((data, ctx) => {
      const isValueRequired = !shouldHideValueBasedOnOperator(data.operator);
      const isValueMissing = Array.isArray(data.value)
        ? data.value.length === 0
        : data.value === '';

      // Only show a validation error on the value if the operator requires a value, and the value is missing
      if (isValueRequired && isValueMissing) {
        ctx.addIssue({
          path: ['value'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });

  const form = useForm<KnackFilter>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: filter
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = form;

  const [selectedFieldKey, selectedOperator] = watch(['field', 'operator']);
  const selectedField = originObject.fields.find((field) => field.key === selectedFieldKey);
  const fieldOperators = selectedField ? getBaseFieldOperators(selectedField) : [];

  const onSubmit = (updatedFilter: KnackFilter) => {
    onSave(updatedFilter);
  };

  const onFieldChange = (
    newFieldKey: KnackFieldKey,
    formOnChange: ControllerRenderProps['onChange']
  ) => {
    const newSelectedField = originObject.fields.find((field) => field.key === newFieldKey);
    const newSelectedFieldOperators = newSelectedField
      ? getBaseFieldOperators(newSelectedField)
      : [];

    // If either the new field or the previous field is a type that uses a select input, we clear the filter value.
    // This prevents displaying the previous value in the input when changing the field (e.g. changing from `connection` to `short_text` would show the previously selected record ID in the input)
    const multiOptionFieldTypes: KnackFieldType[] = ['boolean', 'multiple_choice', 'connection'];
    const shouldClearFilterValue =
      (newSelectedField && multiOptionFieldTypes.includes(newSelectedField.type)) ||
      (selectedField && multiOptionFieldTypes.includes(selectedField.type));

    if (shouldClearFilterValue) {
      if (newSelectedField?.type === 'connection') {
        setValue('value', []);
      } else if (newSelectedField?.type === 'boolean') {
        setValue('value', true);
      } else {
        setValue('value', '');
      }
    }

    // If the field changes, the selected operator might not be in the new list of operators, so we select the operator in the list if that's the case
    const isOperatorInList = (newSelectedFieldOperators as string[]).includes(selectedOperator);

    if (!isOperatorInList) {
      setValue('operator', newSelectedFieldOperators[0]);
    }

    clearFormErrors();
    formOnChange(newFieldKey);
  };

  const renderFieldInput = () => {
    if (!selectedField || shouldHideValueBasedOnOperator(selectedOperator)) {
      return null;
    }

    if (selectedField.type === 'connection') {
      return <ConnectionFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    // TODO: FE-3155 - Implement date_time and timer fields
    // if (selectedField.type === 'date_time') {
    // }

    if (selectedField.type === 'multiple_choice') {
      return <MultipleChoiceFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    if (selectedField.type === 'boolean') {
      return <BooleanFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    return (
      <Input
        className="text-sm"
        intent={errors.value ? 'destructive' : undefined}
        placeholder={t('actions.enter_value')}
        {...register('value')}
      />
    );
  };

  return (
    <Card className="bg-muted p-2 text-sm shadow-none sm:p-2">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Section className="mb-2 flex gap-2">
            <div className="flex-1">
              <Controller
                control={control}
                name="field"
                render={({ field: { value: fieldKey, onChange } }) => (
                  <Select
                    value={fieldKey}
                    onValueChange={(newFieldKey: KnackFieldKey) =>
                      onFieldChange(newFieldKey, onChange)
                    }
                  >
                    <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                    <Select.Content>
                      {originObject.fields.map((field) => (
                        <Select.Item
                          key={field.key}
                          value={field.key}
                          disabled={NOT_IMPLEMENTED_FIELD_TYPES_IN_FILTERS.includes(field.type)}
                        >
                          <span className="flex items-center">
                            <FieldIcon
                              className="mr-2 shrink-0 text-subtle"
                              size={16}
                              name={field.type}
                            />
                            {field.name}
                          </span>
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name="operator"
                render={({ field: { value: operator, onChange } }) => (
                  <Select
                    value={operator}
                    onValueChange={(newOperator) => {
                      clearFormErrors();
                      onChange(newOperator);
                    }}
                  >
                    <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                    <Select.Content>
                      {fieldOperators.map((fieldOperator: string) => (
                        <Select.Item key={fieldOperator} value={fieldOperator}>
                          {t(`operators.${snakeCase(fieldOperator)}`)}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </div>
          </Form.Section>

          <Form.Section className="mb-2">
            {renderFieldInput()}
            {errors.value && (
              <p className="mt-1 text-sm text-destructive">{errors.value.message}</p>
            )}
          </Form.Section>

          <Form.Section className="flex justify-end gap-2">
            <Button intent="secondary" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
            <Button type="submit">{t('actions.save')}</Button>
          </Form.Section>
        </form>
      </FormProvider>
    </Card>
  );
}
