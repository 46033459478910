import React from 'react';
import { Controller, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Input, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackObject } from '@/types/schema/KnackObject';
import { CopyTableOptions } from '@/pages/tables/tables-tree/table-dropdown-menu/duplicate-table-form/DuplicateTableForm';

interface SourceTargetSelectStepProps {
  table: KnackObject;
  errors: FieldErrors;
  copyTableOption: CopyTableOptions;
  app: BuilderApplication;
  register: any;
  control: any;
  handleSelectChange: (value: string) => void;
}

export function SourceTargetSelectStep({
  table,
  errors,
  copyTableOption,
  app,
  register,
  control,
  handleSelectChange
}: SourceTargetSelectStepProps) {
  const [t] = useTranslation();

  return (
    <>
      <Form.Section>
        <Form.Label
          className="text-sm font-medium"
          htmlFor="table-name-input"
          data-testid="duplicate-table-radio-buttons-label"
        >
          {t('components.dialogs.tables.duplicate.copy_fields_from', {
            tableName: table.name
          })}
        </Form.Label>
        <Controller
          control={control}
          name="copyTableOption"
          render={({ field }) => (
            <RadioGroup onValueChange={field.onChange} value={field.value}>
              <RadioGroup.Container>
                <RadioGroup.Item
                  value={CopyTableOptions.NewTable}
                  id="new-table-option"
                  data-testid="new-table-option"
                />
                <Label htmlFor="new-table-option">
                  {t('components.dialogs.tables.duplicate.new_table')}
                </Label>
              </RadioGroup.Container>
              <RadioGroup.Container>
                <RadioGroup.Item
                  value={CopyTableOptions.ExistingTable}
                  id="existing-table-option"
                  data-testid="existing-table-option"
                />
                <Label htmlFor="existing-table-option">
                  {t('components.dialogs.tables.duplicate.existing_table')}
                </Label>
              </RadioGroup.Container>
            </RadioGroup>
          )}
        />
      </Form.Section>
      {copyTableOption === CopyTableOptions.NewTable && (
        <Form.Section>
          <Form.Label
            className="text-sm font-medium"
            htmlFor="table-name-input"
            data-testid="duplicate-table-radio-buttons-label"
          >
            {t('components.dialogs.tables.duplicate.new_table_name', {
              tableName: table.name
            })}
          </Form.Label>
          <Input.Container>
            <Input
              data-testid="table-name-input"
              id="table-name-input"
              type="text"
              title={t('components.dialogs.tables.table_name')}
              intent={errors.tableName && 'destructive'}
              {...register('tableName')}
            />
          </Input.Container>
          {errors.tableName && <p className="text-destructive">{`${errors.tableName.message}`}</p>}
        </Form.Section>
      )}

      {copyTableOption === CopyTableOptions.ExistingTable && (
        <Form.Section>
          <Form.Label
            className="text-sm font-medium"
            htmlFor="table-name-select"
            data-testid="duplicate-table-radio-buttons-label"
          >
            {t('components.dialogs.tables.duplicate.select_a_table')}
          </Form.Label>
          <Select
            onValueChange={handleSelectChange}
            data-testid="display-field-select"
            defaultValue={app?.objects[0].key}
          >
            <Select.Trigger className="w-full truncate rounded-lg" />
            <Select.Content>
              {app?.objects.map((field) => (
                <Select.Item
                  key={field.key}
                  className="truncate hover:bg-muted"
                  value={field.key}
                  data-testid={`display-field-select-option-${field.key}`}
                >
                  {field.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </Form.Section>
      )}
    </>
  );
}
