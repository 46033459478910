import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import {
  MULTIPLE_CHOICE_SORTING_OPTIONS,
  MULTIPLE_CHOICE_TYPE_OPTIONS
} from '@/types/schema/fields/MultipleChoiceField';
import { FormContent } from '@/components/field-settings/multiple-choice/FormContent';

export const multipleChoiceSchema = z.object({
  format: z.object({
    blank: z.string().optional(),
    default: z.string(),
    options: z
      .array(
        z
          .string()
          .trim()
          .min(1, {
            message: t(
              'components.data_table.attributes.field_settings.multiple_choice.option_min_length'
            )
          })
      )
      .nonempty(),
    sorting: z.enum(MULTIPLE_CHOICE_SORTING_OPTIONS),
    type: z.enum(MULTIPLE_CHOICE_TYPE_OPTIONS)
  })
});

const ID_LENGTH = 5;
const DEFAULT_OPTIONS = ['First Choice', 'Second Choice', 'Third Choice'];

export function MultipleChoiceFormSettings() {
  const { getValues } = useFormContext();

  const formOptions: string[] =
    getValues('format.options')?.length > 0 ? getValues('format.options') : DEFAULT_OPTIONS;

  const optionsWithIds = useMemo(
    () => formOptions.map((option) => ({ id: nanoid(ID_LENGTH), option })),
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <FormContent options={optionsWithIds} />
    </div>
  );
}
