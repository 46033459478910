import { type DateTimeFieldConditionalRuleCriteria } from '@/types/schema/fields';
import { getRuleTimeFormatted } from '@/utils/rules/getRuleTimeFormatted';
import i18n from '@/i18n';

export function getDateTimeConditionalRuleCriteriaValue(
  criteria: DateTimeFieldConditionalRuleCriteria
) {
  const dateTimeCriteria = criteria as DateTimeFieldConditionalRuleCriteria;
  const { operator, type, range, value } = dateTimeCriteria;

  if (
    operator === 'is during the previous' ||
    operator === 'is during the last' ||
    operator === 'is during the next' ||
    operator === 'is before the previous' ||
    operator === 'is after the next'
  ) {
    if (!type || !range) return '';
    return i18n.t(`attributes.field_labels.date_time.number_${type.replace(' ', '_')}`, {
      number: range
    });
  }

  if (operator === 'is during the current') {
    if (!type) return '';
    return i18n.t(`attributes.field_labels.date_time.${type}`);
  }

  if (!value.time) {
    return `${value.date}, ${i18n.t('attributes.field_labels.date_time.at_any_time')}` || '';
  }

  const timeString = value.time ? value.time : getRuleTimeFormatted(value.hours, value.minutes);
  return `${value.date || ''} ${timeString}`.trim();
}
