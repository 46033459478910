import { useCallback } from 'react';

import { type BuilderPage, type BuilderPageKey } from '@/types/schema/BuilderPage';
import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

type EligibleLinkablePage = {
  label: string;
  slug: string;
  key: BuilderPageKey;
};

export function usePageHelpers() {
  const { getUserRoleObjectFromProfileKey } = useObjectHelpers();
  const { data: pages = [] } = usePagesQuery();

  const getPageBySlug = (slug: string) => pages.find((page) => page.slug === slug);

  const getPageRoleObjects = (page: BuilderPage, includeAccountsObject = false) => {
    const roleObjects: KnackObject[] = [];

    const allowedProfileKeys: KnackObjectProfileKey[] = page.allowedProfileKeys || [];

    if (includeAccountsObject) {
      allowedProfileKeys.push('all_users');
    }

    allowedProfileKeys.forEach((profileKey) => {
      const roleObject = getUserRoleObjectFromProfileKey(profileKey);

      if (roleObject) {
        roleObjects.push(roleObject);
      }
    });

    return roleObjects;
  };

  // Sort pages by type, with user pages at the end
  const getSortedPages = useCallback(
    (pagesToSort: BuilderPage[]) =>
      pagesToSort.sort((a, b) => {
        if (a.type === b.type) {
          return 0;
        }

        if (a.type === 'user' && b.type !== 'user') {
          return 1;
        }

        return -1;
      }),
    []
  );

  // The start pages are:
  //   - The root pages that are not login or menu pages
  //   - The top child pages of login and menu pages
  const getStartPages = useCallback(() => {
    let startPages: BuilderPage[] = [];

    // Recursive function to get the start pages of a login page or menu page
    function getStartPagesFromLoginOrMenu(page: BuilderPage) {
      if (page.type !== 'menu' && page.type !== 'authentication') {
        return [];
      }

      let loginPageStartPages: BuilderPage[] = [];

      pages.forEach((potentialStartPage) => {
        // Ignore itself, and any top level pages
        if (
          page.key === potentialStartPage.key ||
          (!potentialStartPage.parentSlug && !potentialStartPage.menuPageKey)
        ) {
          return;
        }

        const isChildPage =
          page.type === 'menu'
            ? potentialStartPage.menuPageKey === page.key
            : potentialStartPage.parentSlug === page.slug;

        if (isChildPage) {
          if (potentialStartPage.type === 'page' && !potentialStartPage.sourceObjectKey) {
            loginPageStartPages.push(potentialStartPage);
          }

          if (potentialStartPage.type === 'authentication') {
            loginPageStartPages = loginPageStartPages.concat(
              getStartPagesFromLoginOrMenu(potentialStartPage)
            );
          }
        }
      });

      return loginPageStartPages;
    }

    pages.forEach((page) => {
      // Ignore the non-top level pages
      if (page.parentSlug || page.menuPageKey) {
        return;
      }

      if (page.type === 'authentication') {
        startPages = startPages.concat(getStartPagesFromLoginOrMenu(page));
        return;
      }

      if (page.type === 'menu') {
        startPages = startPages.concat(getStartPagesFromLoginOrMenu(page));
        return;
      }

      if (!page.parentSlug && !page.menuPageKey) {
        startPages.push(page);
      }
    });

    return getSortedPages(startPages);
  }, [getSortedPages, pages]);

  // Get the pages that are eligible to be selected for link/redirection, based on an object key
  // For instance, "Action Links", "Form Submit Rules" and "Page Rules" use this to find eligible pages to link/redirect to.
  const getElegibleLinkablePagesFromObjectKey = useCallback(
    (objectKey: string) => {
      // Recursive function to get the children of a page
      function addChildPages(
        initialEligibleLinkablePages: EligibleLinkablePage[],
        page: BuilderPage,
        label: string
      ) {
        const childPages = pages.filter(
          (p) => p.parentSlug === page.slug || p.menuPageKey === page.key
        );

        childPages.forEach((childPage) => {
          const childLabel = `${label} > ${childPage.name}`;

          if (!childPage.sourceObjectKey || childPage.sourceObjectKey === objectKey) {
            initialEligibleLinkablePages.push({
              label: childLabel,
              slug: childPage.slug,
              key: childPage.key
            });

            if (!childPage.sourceObjectKey) {
              addChildPages(initialEligibleLinkablePages, childPage, childLabel);
            }
          }
        });
      }

      const eligibleLinkablePages: EligibleLinkablePage[] = [];

      getStartPages().forEach((startPage) => {
        eligibleLinkablePages.push({
          label: startPage.name,
          slug: startPage.slug,
          key: startPage.key
        });

        addChildPages(eligibleLinkablePages, startPage, startPage.name);
      });

      return eligibleLinkablePages;
    },
    [getStartPages, pages]
  );

  return {
    getPageBySlug,
    getPageRoleObjects,
    getStartPages,
    getElegibleLinkablePagesFromObjectKey
  };
}
