import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as PencilIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackField } from '@/components/InlineKnackField';
import { FilterForm, NOT_IMPLEMENTED_FIELD_TYPES_IN_FILTERS } from './FilterForm';
import { FilterValue } from './FilterValue';

interface FilterCardProps {
  filter: KnackFilter;
  originObject: KnackObject;
  filterIndex?: number;
  onFilterSave?: (filter: KnackFilter, filterIndex?: number) => void;
  onFilterDelete?: (filterIndex: number) => void;
}

export function FilterCard({
  filter,
  originObject,
  filterIndex,
  onFilterSave,
  onFilterDelete
}: FilterCardProps) {
  const [t] = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const selectedField = originObject.fields.find((field) => field.key === filter.field);

  if (!selectedField) {
    return null;
  }

  if (onFilterSave && isEditing) {
    return (
      <FilterForm
        originObject={originObject}
        filter={filter}
        onCancel={() => {
          setIsEditing(false);
        }}
        onSave={(filterData) => {
          onFilterSave(filterData, filterIndex);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <Card className="group relative bg-muted p-2 text-sm shadow-none sm:p-2">
      <div>
        <InlineKnackField
          fieldType={selectedField.type}
          fieldName={selectedField.name}
          className="bg-subtle"
        />{' '}
        {t(`operators.${snakeCase(filter.operator)}`)}{' '}
        <FilterValue filter={filter} filterField={selectedField} />
      </div>

      {onFilterSave && onFilterDelete && (
        <div className="absolute bottom-0 right-2 top-0 hidden items-center gap-2 group-hover:flex">
          <Button
            intent="minimal"
            aria-label={t('actions.edit')}
            size="xs"
            className="text-subtle"
            onClick={() => setIsEditing(true)}
            disabled={NOT_IMPLEMENTED_FIELD_TYPES_IN_FILTERS.includes(selectedField.type)}
          >
            <PencilIcon size={14} />
          </Button>
          <Button
            intent="minimal"
            aria-label={t('actions.delete')}
            size="xs"
            className="text-subtle"
            onClick={() => {
              if (filterIndex !== undefined) {
                onFilterDelete(filterIndex);
              }
            }}
          >
            <DeleteIcon size={14} />
          </Button>
        </div>
      )}
    </Card>
  );
}
