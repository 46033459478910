import { type DateTimeFieldValidationRuleCriteria } from '@/types/schema/fields';
import { DAYS_OF_THE_WEEK } from '@/utils/constants';
import i18n from '@/i18n';

export function getDateTimeValidationRuleCriteriaValue(
  criteria: DateTimeFieldValidationRuleCriteria
) {
  const dateTimeCriteria = criteria as DateTimeFieldValidationRuleCriteria;
  const { operator, value, type, range } = dateTimeCriteria;

  // The criteria is only a number if the operator is 'is a day of the week', so we can just return that
  if (typeof value === 'number') {
    return DAYS_OF_THE_WEEK[value];
  }

  if (
    operator === 'is during the previous' ||
    operator === 'is during the last' ||
    operator === 'is during the next' ||
    operator === 'is before the previous' ||
    operator === 'is after the next'
  ) {
    if (!type) return '';
    return i18n.t(`attributes.field_labels.date_time.number_${type.replace(' ', '_')}`, {
      number: range
    });
  }

  if (operator === 'is during the current') {
    if (!type) return '';
    return i18n.t(`attributes.field_labels.date_time.${type}`);
  }

  if (operator === 'is between days of the week') {
    const { from_date: fromDate, to_date: toDate } = dateTimeCriteria;

    if (!fromDate || !toDate) {
      return '';
    }

    return i18n.t('attributes.field_labels.date_time.from_to', {
      from: DAYS_OF_THE_WEEK[fromDate as number],
      to: DAYS_OF_THE_WEEK[toDate as number]
    });
  }

  const { from_date: fromDate, to_date: toDate } = value;
  if (fromDate && toDate) {
    const toDateDate = toDate.date || i18n.t('attributes.field_labels.date_time.any_date');
    let toDateTime = '';

    if (!toDate.all_day) {
      if (toDate.time) {
        toDateTime = i18n.t('attributes.field_labels.date_time.at_time', {
          hours: toDate.hours,
          minutes: toDate.minutes
        });
      } else {
        toDateTime = i18n.t('attributes.field_labels.date_time.at_any_time');
      }
    }

    return i18n.t('attributes.field_labels.date_time.between_dates', {
      from: fromDate.date,
      to: [toDateDate, toDateTime]
        .filter((_) => Boolean(_))
        .join(', ')
        .trim()
    });
  }

  const { date, time, hours, minutes } = value;

  if (!date && !time) {
    return i18n.t('attributes.field_labels.date_time.any_date_any_time');
  }

  return `${[
    date || i18n.t('attributes.field_labels.date_time.any_date'),
    time
      ? i18n.t('attributes.field_labels.date_time.at_time', { hours, minutes })
      : i18n.t('attributes.field_labels.date_time.at_any_time')
  ].join(', ')}`;
}
