// import { dateTimeOperators } from '@/types/schema/field-operators/DateTimeOperator';
import { type KnackFieldType } from '@/types/schema/KnackField';
import {
  blankOperators,
  containsOperators,
  dateTimeOperators,
  defaultOperators,
  isAnyOperators,
  isOperators,
  numberOperators,
  stringOperators
} from './field-operators';

export const filterOperatorsByType: Record<KnackFieldType, string[] | null> = {
  short_text: defaultOperators,
  paragraph_text: defaultOperators,
  rich_text: defaultOperators,
  number: numberOperators,
  boolean: [...isOperators],
  concatenation: defaultOperators,
  currency: numberOperators,
  equation: numberOperators,
  auto_increment: numberOperators,
  sum: numberOperators,
  min: numberOperators,
  max: numberOperators,
  average: numberOperators,
  count: numberOperators,
  multiple_choice: [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators],
  date_time: [...dateTimeOperators],
  timer: numberOperators,
  file: [...blankOperators],
  image: [...blankOperators],
  name: defaultOperators,
  email: defaultOperators,
  address: defaultOperators,
  phone: defaultOperators,
  link: [...blankOperators, ...isOperators],
  signature: [...blankOperators],
  rating: numberOperators,
  connection: [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators],
  user_roles: [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators],
  password: [...containsOperators, ...stringOperators]
};
