import {
  Controller,
  useFormContext,
  type FieldArrayWithId,
  type UseFieldArrayUpdate
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type BaseFieldOperator } from '@/types/schema/operators/field-operators';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { type DefaultFiltersFormData } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersDialog';
import { FiltersInput } from '@/pages/pages/settings-panel/view-settings/common/FiltersInput';

interface ViewSettingsDefaultFiltersDialogItemProps {
  formFieldName: `preset_filters.${number}`;
  ruleFieldIndex: number;
  ruleField: FieldArrayWithId<DefaultFiltersFormData, 'preset_filters', 'id'>;
  availableFields: KnackField[];
  update: UseFieldArrayUpdate<DefaultFiltersFormData, 'preset_filters'>;
  remove: (index: number) => void;
}

export function ViewSettingsDefaultFiltersDialogItem({
  formFieldName,
  ruleFieldIndex,
  ruleField,
  availableFields,
  update,
  remove
}: ViewSettingsDefaultFiltersDialogItemProps) {
  const [t] = useTranslation();
  const {
    watch,
    formState: { errors },
    getFieldState
  } = useFormContext<DefaultFiltersFormData>();

  const { getOperatorsForField } = useFieldHelpers();

  const currentOperator = watch(`${formFieldName}.operator`) as BaseFieldOperator;

  const inputValueError = getFieldState(`${formFieldName}.value`).error;

  return (
    <div className="mb-4 space-y-2 rounded-lg bg-subtle p-3">
      <div className="flex gap-2">
        <div className="mt-2 min-w-10 text-right">
          <span>{t('components.rules.where')}</span>
        </div>
        <div className="w-full">
          <div className="flex">
            <div className="-m-1 flex-1 space-y-2 overflow-hidden p-1">
              <div className="flex gap-2">
                <div className="flex-1">
                  <Controller
                    name={`${formFieldName}.field`}
                    render={({ field: { value: fieldKey, onChange } }) => (
                      <Select
                        value={fieldKey}
                        onValueChange={(newFieldKey: KnackFieldKey) => {
                          const defaultOperator = getOperatorsForField(
                            newFieldKey,
                            availableFields
                          )[0];

                          update(ruleFieldIndex, {
                            ...ruleField,
                            operator: defaultOperator,
                            value: ''
                          });
                          onChange(newFieldKey);
                        }}
                        disabled={availableFields.length === 0}
                      >
                        <Select.Trigger
                          placeholder={`${t('actions.select')}...`}
                          className={cn('w-full', {
                            'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                              errors[formFieldName]?.[ruleFieldIndex]?.field
                          })}
                        />
                        <Select.Content>
                          {availableFields.map((field) => (
                            <Select.Item key={field.key} value={field.key}>
                              <span className="flex items-center">
                                <FieldIcon
                                  className="mr-2 shrink-0 text-subtle"
                                  size={16}
                                  name={field.type}
                                />
                                {field.name}
                              </span>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                  <FormErrorMessage errors={errors} name={`${formFieldName}.field`} />
                </div>
                <div className="flex-1">
                  <Controller
                    name={`${formFieldName}.operator`}
                    render={({ field: { value: operator, onChange } }) => (
                      <Select
                        value={operator}
                        onValueChange={(newOperator: BaseFieldOperator) => {
                          onChange(newOperator);
                        }}
                      >
                        <Select.Trigger
                          placeholder={`${t('actions.select')}...`}
                          className="w-full"
                        />
                        <Select.Content>
                          {getOperatorsForField(ruleField.field, availableFields).map(
                            (fieldOperator: string) => (
                              <Select.Item key={fieldOperator} value={fieldOperator}>
                                {t(`operators.${snakeCase(fieldOperator)}`)}
                              </Select.Item>
                            )
                          )}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>
              </div>
              {!shouldHideValueBasedOnOperator(currentOperator) && (
                <>
                  <FiltersInput<DefaultFiltersFormData>
                    error={inputValueError}
                    fieldKey={ruleField.field}
                    formFieldName={`${formFieldName}.value`}
                  />
                  <FormErrorMessage errors={errors} name={`${formFieldName}.value`} />
                </>
              )}
            </div>
            <Button
              intent="minimal"
              aria-label={t('components.rules.delete_condition')}
              size="xs"
              className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
              onClick={() => remove(ruleFieldIndex)}
            >
              <RemoveIcon size={16} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
