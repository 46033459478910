import { type KnackFieldType } from '@/types/schema/KnackField';
import {
  blankOperators,
  characterCountOperators,
  containsOperators,
  dateTimeValidationOperators,
  domainOperators,
  fileTypeOperators,
  hideValueOperators,
  higherLowerOperators,
  isAnyOperators,
  isOneOfOperators,
  isOperators,
  regexOperators,
  sizeOperators,
  stringOperators,
  timeComparisonOperators
} from '@/types/schema/operators/field-operators';

export function generateValidationRuleOperators(fieldType: KnackFieldType) {
  const defaultOperators = [
    ...containsOperators,
    ...isOperators,
    ...stringOperators,
    ...blankOperators,
    ...timeComparisonOperators
  ];

  const textOperators = [
    ...containsOperators,
    ...isOperators,
    ...stringOperators,
    ...blankOperators
  ];
  const extendedTextOperators = [...textOperators, ...characterCountOperators];
  const shortTextOperators = [...extendedTextOperators, ...regexOperators];
  const numericOperators = [
    ...isOperators,
    ...higherLowerOperators,
    ...blankOperators,
    ...isOneOfOperators
  ];
  const choiceOperators = [
    ...isOperators,
    ...containsOperators,
    ...isAnyOperators,
    ...blankOperators
  ];
  const imageFileOperators = [...blankOperators, ...sizeOperators, ...fileTypeOperators];

  switch (fieldType) {
    case 'short_text':
      return shortTextOperators;
    case 'paragraph_text':
    case 'rich_text':
      return extendedTextOperators;
    case 'number':
    case 'currency':
    case 'timer':
      return numericOperators;
    case 'multiple_choice':
    case 'connection':
      return choiceOperators;
    case 'boolean':
      return [...isOperators];
    case 'link':
      return [...isOperators, ...blankOperators];
    case 'rating':
      return [...isOperators, ...higherLowerOperators, ...blankOperators, ...isOneOfOperators];
    case 'image':
    case 'file':
      return imageFileOperators;
    case 'signature':
      return [...blankOperators];
    case 'address':
    case 'name':
    case 'phone':
      return textOperators;
    case 'email':
      return [...textOperators, ...domainOperators];
    case 'date_time':
      return [...dateTimeValidationOperators];
    case 'auto_increment':
    case 'concatenation':
    case 'equation':
    case 'sum':
    case 'min':
    case 'max':
    case 'average':
    case 'count':
      return [...isOperators, ...higherLowerOperators, ...blankOperators, ...isOneOfOperators];
    default:
      return [...defaultOperators];
  }
}

export const validationRuleMap = {
  short_text: {
    operators: generateValidationRuleOperators('short_text')
  },
  paragraph_text: {
    operators: generateValidationRuleOperators('paragraph_text')
  },
  rich_text: {
    operators: generateValidationRuleOperators('rich_text')
  },
  number: {
    operators: generateValidationRuleOperators('number')
  },
  currency: {
    operators: generateValidationRuleOperators('currency')
  },
  multiple_choice: {
    operators: generateValidationRuleOperators('multiple_choice')
  },
  boolean: {
    operators: generateValidationRuleOperators('boolean')
  },
  date_time: {
    operators: generateValidationRuleOperators('date_time')
  },
  timer: {
    operators: generateValidationRuleOperators('timer')
  },
  file: {
    operators: generateValidationRuleOperators('file')
  },
  image: {
    operators: generateValidationRuleOperators('image')
  },
  name: {
    operators: generateValidationRuleOperators('name')
  },
  email: {
    operators: generateValidationRuleOperators('email')
  },
  address: {
    operators: generateValidationRuleOperators('address')
  },
  phone: {
    operators: generateValidationRuleOperators('phone')
  },
  link: {
    operators: generateValidationRuleOperators('link')
  },
  signature: {
    operators: generateValidationRuleOperators('signature')
  },
  rating: {
    operators: generateValidationRuleOperators('rating')
  },
  connection: {
    operators: generateValidationRuleOperators('connection')
  },
  auto_increment: {
    operators: generateValidationRuleOperators('auto_increment')
  },
  concatenation: {
    operators: generateValidationRuleOperators('concatenation')
  },
  equation: {
    operators: generateValidationRuleOperators('equation')
  },
  sum: {
    operators: generateValidationRuleOperators('sum')
  },
  min: {
    operators: generateValidationRuleOperators('min')
  },
  max: {
    operators: generateValidationRuleOperators('max')
  },
  average: {
    operators: generateValidationRuleOperators('average')
  },
  count: {
    operators: generateValidationRuleOperators('count')
  }
};

export function shouldHideOperator(operator: string) {
  const hideValueOperatorsSet = new Set(hideValueOperators);
  // TODO: FE-1944 refine type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return hideValueOperatorsSet.has(operator as any);
}

export function shouldShowUnitSelector(operator: string) {
  const showUnitSelectorOperatorsSet = new Set(sizeOperators);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return showUnitSelectorOperatorsSet.has(operator as any);
}

export const ASSET_BYTE_UNITS = ['bytes', 'kilobytes', 'megabytes'];

// TODO
// remove these when the validation rules are fully implemented
// https://knack.atlassian.net/browse/FE-2480

export function getDisabledValidationRuleCriteriaFields(): KnackFieldType[] {
  const disabledActionFields: KnackFieldType[] = ['date_time', 'user_roles', 'password'];
  return disabledActionFields;
}

export function getDisabledValidationRuleActionFields(): KnackFieldType[] {
  const disabledActionFields: KnackFieldType[] = ['user_roles', 'password'];
  return disabledActionFields;
}
