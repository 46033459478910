import { create } from 'zustand';

type LastVisitedState = {
  lastVisitedTableKey: string | null;
  setLastVisitedTableKey: (tableKey: string) => void;
};

const initialState = {
  lastVisitedTableKey: null
};

export const useLastVisitedStore = create<LastVisitedState>()((set) => ({
  ...initialState,

  setLastVisitedTableKey: (tableKey: string) => {
    set({ lastVisitedTableKey: tableKey });
  }
}));
