import { useEffect, useState } from 'react';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Button, Divider, InputSearch } from '@knack/asterisk-react';
import { t } from 'i18next';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { TableTreeItem } from '@/pages/tables/tables-tree/TablesTreeItem';
import { ROUTES } from '@/Router';

export function TablesTree() {
  const { data: app } = useApplicationQuery();
  const [search, setSearch] = useState('');
  const [filteredTables, setFilteredTables] = useState<KnackObject[]>([]);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const { sortMutation } = useTableMutation();

  function handleSortTables(tables: KnackObject[]) {
    if (tables) {
      const newTableOrder = tables.map((table: KnackObject) => table.key);
      sortMutation.mutate(newTableOrder);
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = filteredTables.findIndex((table) => table.key === active.id);
      const newIndex = filteredTables.findIndex((table) => table.key === over.id);
      const newTables = arrayMove(filteredTables, oldIndex, newIndex);
      setFilteredTables(newTables);
      handleSortTables(newTables);
    }
  };

  useEffect(() => {
    const filtered =
      app?.objects.filter(
        (table) =>
          table.name.toLowerCase().includes(search.toLowerCase()) && table.type === 'StandardObject'
      ) || [];
    setFilteredTables(filtered);
  }, [search, app]);

  return (
    <CollapsiblePanel.Content
      data-testid="table-list-container"
      title={t('components.layout.left_sidebar.tables')}
      shouldHideDivider
    >
      <div className="sticky top-0 z-10 -mt-1 mb-1 bg-muted pb-4 pt-1">
        <InputSearch
          aria-label={t('keywords.search')}
          className="w-full"
          value={search}
          placeholder={t('keywords.search')}
          onChange={(e) => setSearch(e.target.value)}
          data-testid="search-table-input"
        />
      </div>
      <Divider className="mb-4" />
      <Button asChild intent="secondary" className="w-full" data-testid="add-data-button">
        <Link to={`${ROUTES.TABLES_ADD}?origin=builder-next`} data-testid="add-data-link">
          <PlusIcon size={20} data-testid="add-data-icon" className="mr-1" />
          {t('components.add_table.title')}
        </Link>
      </Button>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={filteredTables.map((table) => table.key)}>
          <div className="flex flex-col gap-1 pt-4" data-testid="tables-list">
            {filteredTables?.map((table) => <TableTreeItem key={table.key} table={table} />)}
          </div>
        </SortableContext>
      </DndContext>
    </CollapsiblePanel.Content>
  );
}
