import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Input } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { type MultipleChoiceDraggableOption } from '@/components/field-settings/multiple-choice/FormContent';

export function OptionsListItem({
  option,
  index,
  canDelete,
  onDeleteOption,
  onChangeInputOptionValue
}: {
  option: MultipleChoiceDraggableOption;
  index: number;
  canDelete: boolean;
  onDeleteOption: (index: number) => void;
  onChangeInputOptionValue: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}) {
  const [t] = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: option.id
  });
  const sortableItemStyles = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div className="flex items-center gap-2" ref={setNodeRef} style={sortableItemStyles}>
      <div
        data-testid="option-rearrange-icon"
        className={cn('cursor-grab align-middle active:cursor-grabbing ', {
          visible: isDragging
        })}
        {...listeners}
        {...attributes}
      >
        <DragIcon size={24} />
      </div>
      <Input
        intent={isInvalidInput ? 'destructive' : 'default'}
        data-testid={`options-list-item-${index}`}
        id={`options-list-item-${option.id}`}
        defaultValue={option.option}
        placeholder={t(
          'components.data_table.attributes.field_settings.multiple_choice.option_placeholder',
          {
            index: index + 1
          }
        )}
        onChange={(e) => {
          const optionValue = e.target.value.trim();
          setIsInvalidInput(optionValue.length === 0);
          onChangeInputOptionValue(e, index);
        }}
      />
      <Button
        disabled={!canDelete}
        intent="minimal"
        className="h-8 p-0"
        data-testid={`remove-option-${index}`}
        onClick={() => onDeleteOption(index)}
      >
        <CloseIcon size={24} />
      </Button>
    </div>
  );
}
