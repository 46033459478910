import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { HiMiniViewColumns as FieldIcon } from 'react-icons/hi2';
import { Chip, Tabs, useToast } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { DataTableDisplay } from '@/components/data-table/display/DataTableDisplay';
import { DataTableHeader } from '@/components/data-table/display/header/DataTableHeader';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { FieldsHeader } from '@/pages/tables/fields/header/FieldsHeader';
import { TablesPageTopbar } from '@/pages/tables/TablesPageTopbar';
import { FieldsList } from './fields/FieldsList';
import { useFieldsStore } from './fields/useFieldsStore';

type TablesPageTab = {
  value: 'records' | 'fields';
  children: React.ReactNode;
};

type TablesPageLayoutProps = {
  objectKey: KnackObject['key'];
};

export function TablesPageLayout({ objectKey }: TablesPageLayoutProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { data: app, isLoading: isAppLoading } = useApplicationQuery();
  const [activeTab, setActiveTab] = useState<TablesPageTab['value']>('records');
  const { initialize: initializeFieldsStore } = useFieldsStore().use.actions();
  const { initialize: initializeDataTableStore } = useDataTableStore().use.actions();

  const totalRecords = useDataTableStore().use.totalRecords();
  const isInitialLoad = useDataTableStore().use.isInitialLoad();
  const fields = useFieldsStore().use.fields();

  const items: TablesPageTab[] = [
    {
      children: (
        <span className="flex items-center gap-1">
          <DatabaseIcon
            size={20}
            className={cn('flex-shrink-0', {
              'fill-[url(#svg-gradient-1)]': activeTab === 'records'
            })}
          />
          {t('components.data_table.header.records')}
          <Chip text={String(totalRecords)} size="sm" className="m-0" />
        </span>
      ),
      value: 'records'
    },
    {
      children: (
        <span className="flex items-center gap-1">
          <FieldIcon
            size={20}
            className={cn('flex-shrink-0', {
              'fill-[url(#svg-gradient-1)]': activeTab === 'fields'
            })}
          />
          <span>{t('components.data_table.header.fields')}</span>
          <Chip text={String(fields?.length)} size="sm" className="m-0" />
        </span>
      ),
      value: 'fields'
    }
  ];

  useEffect(() => {
    // If the app data is being fetched, we want to wait until that fetch is done instead of using any potential app data in the react query cache
    // Otherwise, this effect will run with potential stale app data
    if (!app || isAppLoading) return;

    const initializeStores = async () => {
      await initializeFieldsStore({ app, objectKey });
      await initializeDataTableStore({ app, objectKey });
    };

    try {
      void initializeStores();
    } catch (error) {
      presentToast({
        title: t('components.data_table.errors.table_not_found')
      });
    }
  }, [
    app,
    initializeFieldsStore,
    initializeDataTableStore,
    objectKey,
    presentToast,
    t,
    isAppLoading
  ]);

  if (isAppLoading || !app || isInitialLoad) {
    return null;
  }

  return (
    <div className="flex h-full flex-col">
      <div>
        <TablesPageTopbar objectKey={objectKey} />
      </div>
      <div className="flex-1 overflow-auto" key={`fields-tab-${totalRecords}-${fields.length}`}>
        <Tabs
          defaultValue={activeTab}
          className="flex h-full flex-col"
          onValueChange={(value) => setActiveTab(value as TablesPageTab['value'])}
          data-testid="tables-page-tabs"
        >
          <div className="flex justify-between gap-2 px-6 py-3">
            <div data-testid="tables-tabs-list">
              <Tabs.List items={items} />
            </div>
            {activeTab === 'records' && <DataTableHeader />}
            {activeTab === 'fields' && <FieldsHeader />}
          </div>
          <Tabs.Content className="flex-grow overflow-auto p-0" value="records">
            <DataTableDisplay />
          </Tabs.Content>
          <Tabs.Content className="p-0" value="fields">
            <FieldsList />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
}
