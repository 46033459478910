import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  type RouteObject
} from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionHelpers } from '@/hooks/helpers/useSessionHelpers';
import { getApplicationBasePath, getBuilderBaseUrl } from '@/utils/application';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { EditRecordModal } from '@/components/data-table/display/edit-record/EditRecordModal';
import { AddTablePage } from '@/pages/add-table/AddTablePage';
import { DataModelPage } from '@/pages/data-model/DataModelPage';
import { FlowsList } from '@/pages/flows/flows-list/FlowsList';
import { FlowsPage } from '@/pages/flows/FlowsPage';
import { ImportRecordsPage } from '@/pages/import-records/ImportRecordsPage';
import { NotFoundPage } from '@/pages/not-found/NotFoundPage';
import { PageEditor } from '@/pages/pages/page-editor/PageEditor';
import { PagesPage } from '@/pages/pages/PagesPage';
import { RolesPage } from '@/pages/roles/RolesPage';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { TablesPage } from '@/pages/tables/TablesPage';
import { TablesPageContent } from '@/pages/tables/TablesPageContent';
import { ThemeEditor } from '@/pages/themes/theme-editor/ThemeEditor';
import { ThemesList } from '@/pages/themes/themes-list/ThemesList';
import { ThemesPage } from '@/pages/themes/ThemesPage';

type ProtectedRouteProps = {
  children?: JSX.Element;
  flagProtection?: keyof typeof FEATURE_FLAGS;
  allowSharedBuilder?: boolean;
};

export type PageUrlParams = {
  id: string;
};

export enum ROUTES {
  ROOT = '/',
  TABLES = '/tables',
  TABLES_ADD = '/tables/add',
  TABLES_ID = '/tables/:id',
  TABLES_ID_ADD = '/tables/:id/add',
  TABLES_ID_IMPORT = '/tables/:id/import',
  TABLES_ID_RECORD_ID_EDIT = '/tables/:id/record/:recordId/edit',
  FLOWS = '/flows',
  DATA_MODEL = '/data-model',
  PAGES = '/pages',
  PAGES_ID = '/pages/:id',
  THEMES = '/themes',
  THEMES_ID = '/themes/:id',
  ROLES = '/roles',
  PAYMENTS = '/payments',
  SETTINGS = '/settings',
  CATCH_ALL = '*'
}

function ProtectedRoute({
  children,
  flagProtection,
  allowSharedBuilder = true
}: ProtectedRouteProps) {
  const { data: application } = useApplicationQuery();
  const { isSharedBuilder } = useSessionHelpers();

  if (!allowSharedBuilder && isSharedBuilder()) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  // We give priority to the flags, so if the route has flag protection and the flag is enabled, we can continue
  if (flagProtection && isFlagEnabled(flagProtection)) {
    return children || <Outlet />;
  }

  // Next, check if the user has access to non-public features
  const hasAccessToNonPublicFeatures =
    import.meta.env.DEV || !!application?.account.isInternalAccount;
  if (hasAccessToNonPublicFeatures) {
    return children || <Outlet />;
  }

  // Otherwise, redirect to the v3 builder
  window.location.replace(getBuilderBaseUrl());
  return null;
}

export const routeList: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: ROUTES.ROOT,
        element: <Navigate to={ROUTES.TABLES} replace />
      },
      {
        path: ROUTES.ROLES,
        element: <RolesPage />
      },
      {
        path: ROUTES.SETTINGS,
        element: <SettingsPage />
      }
    ]
  },
  {
    element: (
      <ProtectedRoute flagProtection={FEATURE_FLAGS.albato_flows} allowSharedBuilder={false} />
    ),
    children: [
      {
        path: ROUTES.FLOWS,
        element: <FlowsPage />,
        children: [
          {
            index: true,
            element: <FlowsList />
          }
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute flagProtection={FEATURE_FLAGS.page_editor} />,
    children: [
      {
        path: ROUTES.PAGES,
        element: <PagesPage />,
        children: [
          {
            index: true,
            element: <PageEditor isIndex />
          },
          {
            path: ROUTES.PAGES_ID,
            element: <PageEditor />
          }
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute flagProtection={FEATURE_FLAGS.themes} />,
    children: [
      {
        path: ROUTES.THEMES,
        element: <ThemesPage />,
        children: [
          {
            index: true,
            element: <ThemesList />
          },
          {
            path: ROUTES.THEMES_ID,
            element: <ThemeEditor />
          }
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute flagProtection={FEATURE_FLAGS.add_import_v1} />,
    children: [
      {
        path: ROUTES.TABLES_ADD,
        element: <AddTablePage />
      },
      {
        path: ROUTES.TABLES_ID_IMPORT,
        element: <ImportRecordsPage />
      }
    ]
  },
  {
    element: <ProtectedRoute flagProtection={FEATURE_FLAGS.data_table_v1} />,
    children: [
      {
        path: ROUTES.TABLES,
        element: <TablesPage />,
        children: [
          {
            index: true,
            element: <TablesPageContent isIndex />
          },
          {
            path: ROUTES.TABLES_ID,
            element: <TablesPageContent />,
            children: [
              {
                path: ROUTES.TABLES_ID_RECORD_ID_EDIT,
                element: <EditRecordModal />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: ROUTES.DATA_MODEL,
    element: <DataModelPage />
  },
  {
    path: ROUTES.CATCH_ALL,
    element: <NotFoundPage />
  }
];

const router = createBrowserRouter(routeList, {
  basename: getApplicationBasePath()
});

export function Router() {
  return <RouterProvider router={router} />;
}
