import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDocumentDuplicate as DuplicateIcon } from 'react-icons/hi';
import {
  HiLightBulb as AddConditionalIcon,
  HiDocumentCheck as AddValidationIcon,
  HiMiniArrowDown as ArrowDownIcon,
  HiMiniArrowLeft as ArrowLeftIcon,
  HiMiniArrowRight as ArrowRightIcon,
  HiMiniArrowUp as ArrowUpIcon,
  HiTrash as DeleteIcon,
  HiInformationCircle as InfoIcon,
  HiCog6Tooth as SettingsIcon,
  HiMiniEllipsisHorizontal as ThreeDotsIcon
} from 'react-icons/hi2';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { DropdownMenu, Tooltip } from '@knack/asterisk-react';
import { type HeaderContext } from '@tanstack/react-table';

import { type KnackField } from '@/types/schema/KnackField';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { cn } from '@/utils/tailwind';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { AddFieldDialog } from '@/components/field-settings/AddFieldDialog';
import { DeleteFieldDialog } from '@/components/field-settings/DeleteFieldDialog';
import { FieldSettings, type NewFieldState } from '@/components/field-settings/FieldSettings';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { useTablesPageContext } from '@/pages/tables/TablesPageContext';

export function FieldHeader({
  header,
  field
}: {
  header: HeaderContext<string, string>;
  field: KnackField;
}) {
  const [isDeleteFieldDialogOpen, setIsDeleteFieldDialogOpen] = useState(false);
  const [newFieldState, setNewFieldState] = useState<NewFieldState>({
    isBeingCreated: false
  });
  const currentFieldSettings = useDataTableStore().use.currentFieldSettings();
  const filters = useDataTableStorePersist().use.filters();
  const sortBy = useDataTableStorePersist().use.sortBy();
  const shouldSortAutomatically = useDataTableStorePersist().use.shouldSortAutomatically();

  const sortOrder = useDataTableStorePersist().use.sortOrder();
  const fields = useDataTableStore().use.fields();

  const { setSort } = useDataTableStorePersist().use.actions();

  const { refetchPagesInViewport, setCurrentFieldSettings } = useDataTableStore().use.actions();
  const tableKey = useDataTableStore().use.objectKey();
  const draftRows = useDataTableStore().use.draftRows();

  const isDraftModeEnabled = useDataTableStore().use.isDraftModeEnabled();

  const { name, type, meta } = field;
  const { t } = useTranslation();

  const isSorted = shouldSortAutomatically && sortBy === field.key;
  const isFiltered = filters?.rules.some((rule) => rule.field === field.key);

  const { handleSelectToolkitSidebarItem } = useTablesPageContext();

  const hasDescription = meta?.description && meta.description.length > 0;

  const hasOnlyOneField = fields.length === 1;

  const { floatingStyles, refs } = useFloating({
    placement: 'bottom-start',
    middleware: [
      size({
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight}px`;
        }
      }),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });

  useOnClickOutside(refs.floating, (event) => {
    if (event.target instanceof HTMLElement) {
      // Some elements use Portal and are not inside the root element, like dropdowns/popovers, this way we avoid closing the settings each time you use a select field.
      const isInsideRoot = event.target && event.target.closest('#root') !== null;
      if (isInsideRoot) {
        setCurrentFieldSettings(null);
      }
    }
  });

  return (
    <>
      <div
        ref={refs.setReference}
        className={cn('flex h-full items-center border border-l-0 border-subtle bg-muted px-2', {
          'bg-brand-100/70': isSorted,
          'bg-blue-100': isFiltered
        })}
      >
        <div className="flex w-full items-center">
          <FieldIcon name={type} size={14} className="mr-1.5" />

          <p
            className="mr-2 flex-1 items-center truncate text-left"
            data-testid={`data-table-header-${field.key}-name-button`}
          >
            {name}
          </p>

          {isSorted && Object.keys(draftRows).length === 0 && (
            <TextTooltip label={t('components.data_table.sorting.change_sort_type')} asChild>
              <button
                type="button"
                data-testid={`data-table-header-${field.key}-sort-button`}
                className="mr-2 flex size-6 items-center justify-center rounded bg-brand-50 text-xl font-normal"
                onClick={() => {
                  setSort(field.key, sortOrder === 'asc' ? 'desc' : 'asc');
                  refetchPagesInViewport();
                }}
              >
                {isSorted && (sortOrder === 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
              </button>
            </TextTooltip>
          )}

          {hasDescription && (
            <Tooltip>
              <Tooltip.Trigger
                className="m-1"
                data-testid={`data-table-header-${field.key}-description-icon`}
              >
                <InfoIcon />
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="z-10">
                  <p
                    className="inline-flex max-w-[400px] content-center p-2"
                    data-testid={`data-table-header-${field.key}-description-content`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: meta.description || '' }}
                  />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}

          <DropdownMenu>
            <DropdownMenu.Trigger data-testid={`data-table-header-${field.key}-contextual-menu`}>
              <ThreeDotsIcon />
            </DropdownMenu.Trigger>

            <DropdownMenu.Content align="end" alignOffset={-20}>
              <div className="flex flex-col">
                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-edit-field-settings`}
                    disabled={isDraftModeEnabled}
                    onClick={() => {
                      setCurrentFieldSettings(field.key);
                    }}
                  >
                    <SettingsIcon size={18} className="mr-2" />
                    {t('components.data_table.contextual_menu.edit_field_settings')}
                  </DropdownMenu.Item>
                </TextTooltip>
                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-menu-duplicate-field`}
                    disabled={isDraftModeEnabled}
                    onClick={() => {
                      const newFieldIndex = header.column.getIndex();
                      setNewFieldState({
                        isBeingCreated: true,
                        fromFieldId: field.key,
                        newIndex: newFieldIndex
                      });
                    }}
                  >
                    <DuplicateIcon size={18} className="mr-2" />
                    {t('components.data_table.contextual_menu.duplicate_field')}
                  </DropdownMenu.Item>
                </TextTooltip>

                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-menu-insert-field-left`}
                    onClick={() => {
                      const newFieldIndex = header.column.getIndex() - 1;
                      setNewFieldState({
                        isBeingCreated: true,
                        newIndex: newFieldIndex
                      });
                    }}
                    disabled={isDraftModeEnabled}
                  >
                    <ArrowLeftIcon size={18} className="mr-2" />
                    {t('components.data_table.contextual_menu.insert_field_left')}
                  </DropdownMenu.Item>
                </TextTooltip>

                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-menu-insert-field-right`}
                    onClick={() => {
                      const newFieldIndex = header.column.getIndex();
                      setNewFieldState({
                        isBeingCreated: true,
                        newIndex: newFieldIndex
                      });
                    }}
                    disabled={isDraftModeEnabled}
                  >
                    <ArrowRightIcon size={18} className="mr-2" />
                    {t('components.data_table.contextual_menu.insert_field_right')}
                  </DropdownMenu.Item>
                </TextTooltip>

                <DropdownMenu.Separator />

                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-menu-sort-asc`}
                    className={cn('', {
                      'font-bold': isSorted && sortOrder === 'asc'
                    })}
                    onClick={() => {
                      setSort(field.key, 'asc');
                      refetchPagesInViewport();
                    }}
                    disabled={isDraftModeEnabled}
                  >
                    <ArrowDownIcon size={18} className="mr-2" />
                    {t('components.data_table.sorting.sort_a_z')}
                  </DropdownMenu.Item>
                </TextTooltip>
                <TextTooltip
                  label={
                    isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''
                  }
                >
                  <DropdownMenu.Item
                    data-testid={`data-table-header-${field.key}-contextual-menu-sort-desc`}
                    className={cn('', {
                      'font-bold': isSorted && sortOrder === 'desc'
                    })}
                    onClick={() => {
                      setSort(field.key, 'desc');
                      refetchPagesInViewport();
                    }}
                    disabled={isDraftModeEnabled}
                  >
                    <ArrowUpIcon size={18} className="mr-2" />
                    {t('components.data_table.sorting.sort_z_a')}
                  </DropdownMenu.Item>
                </TextTooltip>
                <DropdownMenu.Separator />

                <DropdownMenu.Item
                  onClick={() => handleSelectToolkitSidebarItem('validationRules')}
                  data-testid={`data-table-header-${field.key}-contextual-menu-add-validation-rule`}
                >
                  <AddValidationIcon size={18} className="mr-2" />
                  {t('components.data_table.contextual_menu.view_validation_rules')}
                </DropdownMenu.Item>

                <DropdownMenu.Item
                  onClick={() => handleSelectToolkitSidebarItem('conditionalRules')}
                  data-testid={`data-table-header-${field.key}-contextual-menu-add-conditional-rule`}
                >
                  <AddConditionalIcon size={18} className="mr-2" />
                  {t('components.data_table.contextual_menu.view_conditional_rules')}
                </DropdownMenu.Item>
              </div>

              <DropdownMenu.Separator />
              <div className="flex flex-col">
                <TextTooltip
                  label={(() => {
                    if (isDraftModeEnabled) {
                      return t('components.data_table.disabled_because_draftmode');
                    }
                    if (hasOnlyOneField) {
                      return t('components.data_table.contextual_menu.delete_last_field');
                    }
                    return '';
                  })()}
                >
                  <DropdownMenu.Item
                    disabled={isDraftModeEnabled || hasOnlyOneField}
                    data-testid={`data-table-header-${field.key}-contextual-menu-delete-field`}
                    className="w-full text-destructive"
                    onClick={() => {
                      setIsDeleteFieldDialogOpen(true);
                    }}
                  >
                    <DeleteIcon size={18} className="mr-2" />
                    {t('components.data_table.contextual_menu.delete_field')}
                  </DropdownMenu.Item>
                </TextTooltip>
              </div>
            </DropdownMenu.Content>
          </DropdownMenu>
        </div>
      </div>
      {currentFieldSettings === field.key && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="mt-0.5 w-[360px] overflow-auto rounded-lg bg-base p-1 shadow-lg"
        >
          <FieldSettings
            objectKey={tableKey}
            fieldId={field.key}
            initialType={type}
            onCancel={() => setCurrentFieldSettings(null)}
            onSave={() => setCurrentFieldSettings(null)}
            newFieldState={newFieldState}
          />
        </div>
      )}

      <button
        type="button"
        {...{
          onMouseDown: header.header.getResizeHandler(),
          onTouchStart: header.header.getResizeHandler()
        }}
        className="group absolute -right-1 top-0 z-10 flex h-full w-2 cursor-col-resize select-none p-0"
      >
        <span className="m-auto h-full w-0.5 rounded-lg bg-transparent opacity-0 transition-opacity duration-300 group-hover:bg-brand-300 group-hover:opacity-100" />
      </button>

      {isDeleteFieldDialogOpen && (
        <DeleteFieldDialog
          tableKey={tableKey}
          field={field}
          onClose={() => setIsDeleteFieldDialogOpen(false)}
        />
      )}

      {newFieldState.isBeingCreated && (
        <AddFieldDialog
          objectKey={tableKey}
          newFieldState={newFieldState}
          onClose={() =>
            setNewFieldState({
              isBeingCreated: false
            })
          }
        />
      )}
    </>
  );
}

export const MemoizedFieldHeader = memo(FieldHeader);
