import { generatePath, Navigate, useParams } from 'react-router-dom';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { DataTableStoreProvider } from '@/components/data-table/useDataTableStore';
import { TablesPageLayout } from '@/pages/tables/TablesPageLayout';
import { ROUTES, type PageUrlParams } from '@/Router';
import { FieldsStoreProvider } from './fields/useFieldsStore';

export function TablesPageWrapper({ objectKey }: { objectKey: KnackObject['key'] }) {
  return (
    // If you need the state of this component outside the provider, use the globalStore instead of moving the provider to a parent.
    <DataTableStoreProvider>
      <FieldsStoreProvider>
        <TablesPageLayout objectKey={objectKey} data-testid="table-page-layout" />
      </FieldsStoreProvider>
    </DataTableStoreProvider>
  );
}

export function TablesPageContent({ isIndex = false }: { isIndex?: boolean }) {
  const urlParams = useParams<PageUrlParams>();
  const { data: app } = useApplicationQuery();

  // TODO: We need it until we complete the following tickets:
  // - FE-1758: https://knack.atlassian.net/browse/FE-1758
  // - FE-1564: https://knack.atlassian.net/browse/FE-1564
  const isUserOrPaymentsTable = (tableType: KnackObject['type']) =>
    tableType === 'EcommercePaymentObject' ||
    tableType === 'EcommercePaymentMethodObject' ||
    tableType === 'UserObject';

  const defaultTable = app?.objects[0] || null;
  const hasAtLeastOneValidTable =
    !!app?.objects?.length && defaultTable?.key && !isUserOrPaymentsTable(defaultTable.type);

  const defaultRedirectPath = hasAtLeastOneValidTable
    ? generatePath(ROUTES.TABLES_ID, { id: app?.objects[0].key })
    : generatePath(ROUTES.TABLES);

  if (isIndex) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  const { id: tableKey } = urlParams;
  if (!tableKey) {
    throw new Error('No table key provided');
  }

  // TODO: We need it until we complete the following tickets:
  // - FE-1758: https://knack.atlassian.net/browse/FE-1758
  // - FE-1564: https://knack.atlassian.net/browse/FE-1564
  const tableType = app?.objects.find((obj) => obj.key === tableKey)?.type;
  if (tableType && isUserOrPaymentsTable(tableType)) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  // Force a complete remount using key when tableKey changes, this way we ensure that the zustand store is not re-used
  return <TablesPageWrapper objectKey={tableKey as KnackObject['key']} key={tableKey} />;
}
