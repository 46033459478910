import { type RuleType } from '@/types/schema/rules/RuleType';

export const containsOperators = ['contains', 'does not contain'] as const;
export const isOperators = ['is', 'is not'] as const;
export const stringOperators = ['starts with', 'ends with'] as const;
export const blankOperators = ['is blank', 'is not blank'] as const;
export const isOneOfOperators = ['is one of', 'is not one of'] as const;
export const higherLowerOperators = ['higher than', 'lower than'] as const;
export const isAnyOperators = ['is any'] as const;
export const characterCountOperators = [
  'character count is',
  'character count is not',
  'character count is higher than',
  'character count is lower than'
] as const;
export const regexOperators = [
  'matches regular expression',
  'does not match regular expression'
] as const;
export const timeComparisonOperators = [
  'is before',
  'is after',
  'is before current time',
  'is after current time'
] as const;
export const hideValueOperators = [
  'is blank',
  'is not blank',
  'is any',
  'is today',
  'is before today',
  'is after today',
  'is before current time',
  'is after current time',
  'is today or before',
  'is today or after',
  'is a future date',
  'is not a future date',
  'has changed',
  'user'
] as const;
export const sizeOperators = ['size is less than', 'size is greater than'] as const;
export const fileTypeOperators = ['file type is', 'file type is not'] as const;
export const domainOperators = ['domain must equal', 'domain must not equal'] as const;
export const geocodingOperators = ['near'] as const;

export const timeOperators = [...isOperators, ...timeComparisonOperators, ...blankOperators];

export const dateTimeOperators = [
  ...isOperators,
  ...timeComparisonOperators,
  'is during the current',
  'is during the previous',
  'is during the next',
  'is during the last',
  'is before the previous',
  'is after the next',
  'is today',
  'is today or before',
  'is today or after',
  'is before today',
  'is after today',
  ...blankOperators
] as const;

export const dateTimeValidationOperators = [
  ...dateTimeOperators,
  'is a day of the week',
  'is between days of the week',
  'is between dates',
  'is a future date',
  'is not a future date'
] as const;

export const defaultOperators = [
  ...containsOperators,
  ...isOperators,
  ...stringOperators,
  ...blankOperators
];

export const numberOperators = [...isOperators, ...higherLowerOperators, ...blankOperators];

export type BaseFieldOperator =
  | (typeof defaultOperators)[number]
  | (typeof isAnyOperators)[number]
  | (typeof numberOperators)[number]
  | (typeof timeOperators)[number]
  | (typeof dateTimeOperators)[number];

export type DateTimeOperator = (typeof dateTimeOperators)[number];

// As more rule types are introduced into the code, this will need to be updated to reflect the necessary conditionals
export type DateTimeRuleOperator<T extends RuleType> =
  | (T extends 'validation' ? (typeof dateTimeValidationOperators)[number] : DateTimeOperator)
  | (T extends 'conditional' ? DateTimeOperator : never);
