import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields/DateTimeField';
import { type KnackField } from '@/types/schema/KnackField';
import {
  getDateFormat,
  getUTCFromRawValue
} from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import {
  getAmPmFormatFrom24HourTime,
  getHoursFromTime,
  getMinutesFromTime
} from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';
import { getOptionFromDefaultValue } from '@/components/field-settings/boolean/DefaultValue';

const getDefaultValueFromDateTimeField = (fieldFormat: DateTimeFieldFormat) => {
  const defaultValueData: DateTimeRawValue = {};

  const bothDefaultValuesAreNone =
    fieldFormat?.default_type === 'none' && fieldFormat?.time_type === 'none';
  const oneDefaultValueIsNoneAndOtherFormatIsIgnore =
    (fieldFormat?.default_type === 'none' && fieldFormat?.time_format === 'Ignore Time') ||
    (fieldFormat?.time_type === 'none' && fieldFormat?.date_format === 'Ignore Date');

  if (bothDefaultValuesAreNone || oneDefaultValueIsNoneAndOtherFormatIsIgnore) {
    return {
      value: '',
      rawValue: ''
    };
  }

  if (fieldFormat?.default_type === 'current' || fieldFormat?.default_type === 'none') {
    const dateFormat = getDateFormat(fieldFormat?.date_format || 'mm/dd/yyyy');
    const todayFormatted = DateTime.now().toFormat(dateFormat);
    defaultValueData.date = todayFormatted;
  }

  if (fieldFormat?.default_type === 'date' && fieldFormat?.default_date) {
    const date = fieldFormat?.default_date;
    defaultValueData.date = date;
  }

  if (fieldFormat?.time_type === 'current' || fieldFormat?.time_type === 'none') {
    const todayTime = DateTime.now().toISOTime();

    defaultValueData.time = todayTime;
    defaultValueData.hours = getHoursFromTime(todayTime);
    defaultValueData.minutes = getMinutesFromTime(todayTime);
    defaultValueData.am_pm = getAmPmFormatFrom24HourTime(todayTime);
  }

  if (fieldFormat?.time_type === 'time' && fieldFormat?.default_time) {
    const time = fieldFormat?.default_time;

    defaultValueData.time = time;
    defaultValueData.hours = getHoursFromTime(time);
    defaultValueData.minutes = getMinutesFromTime(time);
    defaultValueData.am_pm = getAmPmFormatFrom24HourTime(time);
  }

  defaultValueData.iso_timestamp =
    getUTCFromRawValue(defaultValueData, { ...fieldFormat }).toISO({ includeOffset: false }) || '';

  return {
    value: defaultValueData?.date,
    rawValue: defaultValueData
  };
};

export function getDefaultValueFromField(field: KnackField) {
  if (field?.type === 'boolean') {
    return {
      value: getOptionFromDefaultValue(field.format?.default, field.format?.format) || '',
      rawValue: field?.format?.default || false
    };
  }

  if (field?.type === 'multiple_choice') {
    if (field.format?.default === 'kn-blank') {
      return {
        value: '',
        rawValue: ''
      };
    }
    return {
      value: field?.format?.default || '',
      rawValue: field?.format?.default || ''
    };
  }

  if (field?.type === 'date_time') {
    return getDefaultValueFromDateTimeField(field?.format);
  }

  return {
    value: field?.default || '',
    rawValue: field?.default || ''
  };
}
